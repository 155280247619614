import request from '@/utils/request'
import baseURL from '@/utils/baseURL'

//加入派对
export function login(data) {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/login',
    method: 'post',
    data
  })
}

export function chat(data) {
    return request({
      url: baseURL['pekingMonsters'] + '/v2/chat',
      method: 'post',
      data,
    })
}

 export function initial() {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/player3',
    method: 'get',
    // data
  })
}
export function timeOut() {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/expiration',
    method: 'post',
    // data
  })
}
//音频接口
export function fetch_rtc_token(data) {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/fetch_rtc_token',
    method: 'post',
    data
  })
}
//活动开始状态
export function party_status() {
  return request({
    url: baseURL['pekingMonsters'] + '/v99/party_status',
    method: 'get',
    // data
  })
}

//opensea
export function opensea(params) {
  return request({
    url: baseURL['item'] + '/v2/item/list_all_my_peking_monsters',
    method: 'get',
     params
  })
}

//刷礼物
export function shower_gifts(data) {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/shower_gifts',
    method: 'post',
    data
  })
}

//用瓶盖兑换party币
export function exchange_play_token(data) {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/exchange_play_token',
    method: 'post',
    data
  })
}

//用户余额
export function balance_of(params) {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/balance_of',
    method: 'get',
    params
  })
}

//展示比赛场次 历史
export function world_cup_history() {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/world_cup/list/history',
    method: 'get',
  })
}

//展示比赛场次 可投注
export function world_cup_active() {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/world_cup/list/active',
    method: 'get',
  })
}

//展示比赛场次 可投注
export function world_cup_account_info() {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/world_cup/account_info',
    method: 'get',
  })
}

//个人历史投注信息
export function world_cup_account_history() {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/world_cup/account/history',
    method: 'get',
  })
}

//展示比赛排名
export function world_cup_rank() {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/world_cup/rank',
    method: 'get',
  })
}

//投注
export function world_cup_betting(data) {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/world_cup/betting',
    method: 'post',
    data
  })
}

//投注
export function gift_rank(data) {
  return request({
    url: baseURL['pekingMonsters'] + '/v2/gift_rank',
    method: 'get',
    data
  })
}

//世界杯换衣服
export function change_clothes(query) {
  return request({
    url: baseURL["pekingMonsters"] + `/v2/world_cup/change_clothes`,
    method: "get",
    params:query
  });
}
