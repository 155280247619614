<template>
  <div class="music">
    <div class="pc_page">
      <div class="musicReady" v-show="musicStart == 0">
        <div class="active opacityActive" ref="active">
          <video
            muted
            width="100%"
            autoplay
            loop
            poster="https://img.ohdat.io/peking_monsters/website/assets/icon/music/no_open.jpeg"
          >
            <source
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/no_open.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div class="bgmv" ref="bgmv">
          <video
            muted
            width="100%"
            autoplay
            loop
            poster="https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n3.jpeg"
          >
            <source
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n3.mp4"
              type="video/mp4"
            />
          </video>
        </div>
        <div class="mv">
          <video
            muted
            width="100%"
            id="video"
            poster="https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n3.jpeg"
          >
            <source
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n3.mp4"
              type="video/mp4"
            />
            <!-- <source
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/join.mp4"
              type="video/mp4"
            /> -->
          </video>
        </div>
        <div class="up" ref="up">
          <div class="top" ref="top">
            <div class="topin" @click="changeLang">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/topbox.png"
              />
              <div class="zh engBold" v-if="decipherment">ENGLISH</div>
              <div class="zh chinBold" v-else style="top: -1px">中文</div>
            </div>
          </div>
          <div class="topbox" ref="topbox">
            <div class="topbox_l">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/topbox_l.png"
              />
              <div class="endding">
                <p class="zh chinBold" v-if="decipherment">
                  欢迎来到 Ruakkkk n Rawwww Party
                </p>
                <p class="zh engM" v-else>Welcome to the <br />Ruakkkk n Rawwww Party</p>
                <!-- <p class="zh chinBold" v-if="decipherment">
                  (距离本场结束<span>{{ time }}</span
                  >)
                </p> -->
                <!-- <p class="zh engM" v-else>
                  (<span>{{ time }}</span> Left)
                </p> -->
              </div>
            </div>
            <div class="topbox_r">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/topbox_r.png"
              />
              <div class="musicBox">
                <div class="musicImg">
                  <img :src="singerImg" />
                </div>
                <div class="musicCon">
                  <p class="singerName">{{ singerName }}</p>
                  <p class="singer">{{ singer }}</p>
                </div>
                <div class="play">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/pause.png"
                    @click="onStartClick"
                    v-if="!start"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/play.png"
                    @click="onEndClick"
                    v-else
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="login">
            <!-- 未登录 -->
            <div class="no_login leftImg" v-if="!loginWallet">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/no_login.png"
              />
            </div>
            <div v-else>
              <!-- 没有票 -->
              <div class="leftImg" v-if="!haveTicket">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/no_login.png"
                />
                <div class="pin">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/no.png"
                  />
                </div>
              </div>
              <!-- 有票 -->
              <div class="leftImg have_ticket" v-else ref="leftImg">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/have_tick.png"
                />
                <div class="pin">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/heart.png"
                  />
                </div>
              </div>
            </div>

            <div class="cenbox" ref="cenbox">
              <div class="cenboxTop">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/cenBox.png"
                />
                <!-- 未登录 -->
                <div class="connect" v-if="!loginWallet">
                  <!-- < pclass="zh chinBold" v-if="decipherment">欢迎来到北海怪兽音乐会</p>
                <p class="zh engM" v-else>Welcome to the Reveal Partyt</p> -->
                  <div class="zh_wallet chinBold" v-if="decipherment">
                    请连接钱包，<br />做最燥的自己 ！
                  </div>
                  <div class="zh_wallet engM" v-else>
                    Connect wallet and<br />
                    pick your avatar <br />to enter the party！
                  </div>
                  <div class="wallet" @click="connectWallet">
                    <img
                      src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/connect1.png"
                    />
                    <div class="btn chinBold" v-if="decipherment">连接钱包</div>
                    <div class="btn engM" v-else>CONNECT WALLET</div>
                  </div>
                </div>
                <div v-else>
                  <!-- 无票 -->
                  <!-- <div class="noTicket">
                    <div class="address">
                      <span class="engM">HI,</span>{{ walletAddress }}
                    </div>
                    <div class="no_content chinBold" v-if="decipherment">
                      你不是holder，<br />就站这听吧！
                    </div>
                    <div
                      class="no_content engM"
                      v-else
                      style="font-size: 1.625rem"
                    >
                      You don't own a Peking Monsters. <br />Get it on OpenSea！
                    </div>
                    <div class="opensea">
                      <img
                        src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/connect1.png"
                      />
                      <div class="btn" @click="openseaLink">OPENSEA.IO</div>
                    </div>
                  </div> -->
                  <!-- 有票 -->
                  <div class="haveTicket">
                    <div class="tip chinBold" v-if="decipherment">选择头像</div>
                    <div class="tip engBold" v-else>Pick Your Avatar</div>
                    <img
                      src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/box1.png"
                      class="tabimg"
                      v-if="selTabIndex == 0"
                    />
                    <img
                      src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/box2.png"
                      class="tabimg"
                      v-else-if="selTabIndex == 1"
                    />
                    <img
                      src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/box3.png"
                      class="tabimg"
                      v-else-if="selTabIndex == 2"
                    />
                    <img
                      src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/box4.png"
                      class="tabimg"
                      v-else
                    />
                    <div class="selTab">
                      <div
                        class="item"
                        v-for="(item, index) in itemslist"
                        :key="index"
                        :style="selTabIndex === index ? 'color:#744C00' : ''"
                        @click="seltabList(index)"
                      >
                        {{ item }}
                      </div>
                    </div>
                    <!-- 无票 -->
                    <div class="noTicket" v-if="showNft">
                      <div class="address">
                        <span class="engM">HI,</span>{{ walletAddress }}
                      </div>
                      <div class="no_content chinBold" v-if="decipherment">
                        你不是holder，<br />就站这听吧！
                      </div>
                      <div
                        class="no_content engM"
                        v-else
                        style="font-size: 1.625rem"
                      >
                        You don't own a {{ holderNft }}. <br />Get it on
                        OpenSea！
                      </div>
                      <div class="opensea">
                        <img
                          src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/connect1.png"
                        />
                        <div class="btn" @click="openseaLink">
                          OPENSEA.IO
                        </div>
                      </div>
                    </div>
                    <!-- peking monster -->
                    <div
                      class="peopleList"
                      :class="[headarImg.length > 5 ? '' : 'center']"
                      @scroll="handleScroll($event)"
                      ref="peopleList"
                      v-if="selTabIndex == 0"
                    >
                      <div
                        class="oimg"
                        :class="[oimgIndex == index ? 'oimgbg' : '']"
                        v-for="(item, index) in arr"
                        :key="index"
                        @click="
                          selImg(
                            item.token_address,
                            item.token_id,
                            item.target_info.cover.thumbnail_url,
                            currentAccounttext,
                            index
                          )
                        "
                      >
                        <img :src="item.target_info.cover.thumbnail_url" />
                        <div
                          class="sel"
                          v-show="oimgIndex == index ? !selected : selected"
                        >
                          <img
                            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/select.png"
                          />
                        </div>
                      </div>
                      <div class="engM loading" v-show="loading">
                        <!-- Loading... -->
                      </div>
                    </div>

                    <!-- mefers -->
                    <div
                      v-else-if="selTabIndex == 1"
                      class="mefersList"
                      @scroll="handleScroll1($event)"
                    >
                      <div
                        class="oimg"
                        v-for="(item, index) in arrMefers"
                        :key="index"
                        @click="
                          selImgMefrs(
                            item.contract,
                            item.token_id,
                            item.img,
                            currentAccounttext,
                            index
                          )
                        "
                      >
                        <img :src="item.img" />
                        <div
                          class="sel"
                          v-show="
                            mefersSelIndex == index ? !selected : selected
                          "
                        >
                          <img
                            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/select.png"
                          />
                        </div>
                        <div class="engM loading" v-show="loadingMefers">
                          <!-- Loading... -->
                        </div>
                      </div>
                    </div>
                    <!-- doge -->
                    <div
                      v-else-if="selTabIndex == 2"
                      class="dogeList"
                      @scroll="handleScroll2($event)"
                    >
                      <div
                        class="oimg"
                        v-for="(item, index) in arrdoge"
                        :key="index"
                        @click="
                          selImgdoge(
                            item.contract,
                            item.token_id,
                            item.img,
                            currentAccounttext,
                            index
                          )
                        "
                      >
                        <img :src="item.img" />
                        <div
                          class="sel"
                          v-show="dogeSelIndex == index ? !selected : selected"
                        >
                          <img
                            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/select.png"
                          />
                        </div>
                        <div class="engM loading" v-show="loadingdoge">
                          <!-- Loading... -->
                        </div>
                      </div>
                    </div>
                    <!-- dudu -->
                    <div
                      v-else-if="selTabIndex == 3"
                      class="duduList"
                      @scroll="handleScroll3($event)"
                    >
                      <div
                        class="oimg"
                        v-for="(item, index) in arrdudu"
                        :key="index"
                        @click="
                          selImgdudu(
                            item.contract,
                            item.token_id,
                            item.img,
                            currentAccounttext,
                            index
                          )
                        "
                      >
                        <img :src="item.img" />
                        <div
                          class="sel"
                          v-show="duduSelIndex == index ? !selected : selected"
                        >
                          <img
                            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/select.png"
                          />
                        </div>
                        <div class="engM loading" v-show="loadingdudu">
                          <!-- Loading... -->
                        </div>
                      </div>
                    </div>
                    <div class="footbtn" v-show="!showNft">
                      <div class="ipnt">
                        <span class="chinBold" v-if="decipherment"
                          >你的名字:</span
                        >
                        <span class="engM" v-else>Your Rock Name: </span>
                        <input
                          type="text"
                          v-if="decipherment"
                          class="chinBold"
                          v-model="inputAddress"
                          placeholder="你的名字"
                          maxlength="8"
                        />
                        <input
                          type="text"
                          v-else
                          class="engM"
                          v-model="inputAddress"
                          placeholder="Your Rock Name"
                          maxlength="8"
                        />
                      </div>
                      <div class="join" @click="join">
                        <img
                          src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/connect.png"
                        />
                        <div
                          class="btn chinBold"
                          v-if="decipherment"
                          style="top: -1px"
                        >
                          加入
                        </div>
                        <div class="btn engM" v-else>JOIN</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- 无票 -->
              <div class="cenboxFooter" v-if="!haveTicket || !loginWallet">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/no_open.png"
                />
              </div>
              <!-- 有票 -->
              <div class="cenboxFooter" v-else>
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/join.png"
                />
              </div>
            </div>
            <!-- 无票 -->
            <div class="no_tick rightImg" v-if="!haveTicket">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/no_tick.png"
              />
            </div>
            <!-- 有票 -->
            <div class="have_tick rightImg" v-else ref="rightImg">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/please.png"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="musicStart" v-show="musicStart == 1">
        <!-- 转场动画 -->
        <div ref="transform" class="transform">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform2_new.gif"
            v-show="transformNew == 2"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform3_new.gif"
            v-show="transformNew == 3"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform4_new.gif"
            v-show="transformNew == 4"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform5_new.gif"
            v-show="transformNew == 5"
          />
        </div>

        <!-- <video
          width="100%"
          ref="transform"
          class="transform"
          style="display: none"
          :poster="posterTransform"
        >
          <source :src="transformSrc" type="video/mp4" />
        </video> -->

        <!-- 大背景视频 -->
        <video
          autoplay
          loop
          muted
          width="100%"
          ref="videoDown"
          class="videobg"
          :poster="posterBg"
        >
          <source :src="videoDsrc" type="video/mp4" />
        </video>
        <div class="fly1 fly" id="fly1"></div>
        <div class="fly2 fly" id="fly2"></div>
        <div class="fly3 fly" id="fly3"></div>
        <!-- 病毒雨 -->
        <div class="rain_them3" v-show="rainThem3">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rain/them3_rain.gif"
          />
        </div>
        <div class="rain_them4" v-show="rainThem4">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rain/them4_rain.gif"
          />
        </div>
        <div class="rain_them4" v-show="rainThem5">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rain/them5_rain.gif"
          />
        </div>
        <!-- 瓶盖雨 -->
        <div class="caps_them3" v-show="capsThem3">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/caps/them3_caps.gif"
          />
        </div>
        <div class="caps_them4" v-show="capsThem4">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/caps/them4_caps.gif"
          />
        </div>
        <div class="caps_them4" v-show="capsThem5">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/caps/them5_caps.gif"
          />
        </div>
        <!-- 火箭 -->
        <div class="rocket_them3" v-show="rocketThem3">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rocket/them3_rocket.gif"
          />
         >
          <div class=" userName chinBold" v-if="decipherment">{{ rocketName }} 醒来了！</div>
          <div v-else class="userName engBold">{{ rocketName }}’s soul: SOLD</div>
        </div>
        <div class="rocket_them4" v-show="rocketThem4">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rocket/them4_rocket.gif"
          />
          <div class=" userName chinBold" v-if="decipherment">{{ rocketName }} 是最燥的！</div>
          <div v-else class="userName engBold">{{ rocketName }} crush’em all</div>
        </div>
        <div class="rocket_them4" v-show="rocketThem5">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rocket/them5_rocket.gif"
          />
          <div class=" userName chinBold" v-if="decipherment">{{ rocketName }} 笑起来了？？</div>
          <div v-else class="userName engBold">{{ rocketName }} to the memes</div>
          
        </div>
        <!-- 冷焰火 -->
        <div class="fire fire1" v-show="fire1">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/1.gif"
          />
        </div>
        <div class="fire fire2" v-show="fire2">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/2.gif"
          />
        </div>
        <div class="fire fire3" v-show="fire3">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/3.gif"
          />
        </div>
        <div class="fire fire4" v-show="fire4">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/4.gif"
          />
        </div>
        <div class="fire fire5" v-show="fire5">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/5.gif"
          />
        </div>
        <div class="fire fire6" v-show="fire6">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/6.gif"
          />
        </div>
        <div class="fire fire7" v-show="fire7">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/7.gif"
          />
        </div>
        <div class="fire fire8" v-show="fire8">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/8.gif"
          />
        </div>

        <div id="popup" class="hide">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_1.gif"
            v-if="yrNum == 7"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_2.gif"
            v-else-if="yrNum == 8"
            class="yr"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_3.gif"
            v-else-if="yrNum == 9"
            class="yr"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_4.gif"
            v-else-if="yrNum == 10"
            class="yr"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_5.gif"
            v-else-if="yrNum == 11"
            class="yr"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_6.gif"
            v-else-if="yrNum == 12"
            class="yr"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/dy_yr.gif"
            v-else-if="yrNum == 13"
            class="yr"
          />
        </div>
        <div id="popup2" class="hide">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_1.gif"
            v-if="mmNum == 7"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_2.gif"
            v-else-if="mmNum == 8"
            class="mm2"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_3.gif"
            v-else-if="mmNum == 9"
            class="mm3"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_4.gif"
            v-else-if="mmNum == 10"
            class="mm4"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_5.gif"
            v-else-if="mmNum == 11"
            class="mm5"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_6.gif"
            v-else-if="mmNum == 12"
            class="mm6"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/dy_mm.gif"
            v-else-if="mmNum == 13"
            class="mm6"
          />
        </div>
        <div id="popup3" class="hide">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_1.gif"
            v-if="ggNum == 7"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_2.gif"
            v-else-if="ggNum == 8"
            class="gg2"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_3.gif"
            v-else-if="ggNum == 9"
            class="gg3"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_4.gif"
            v-else-if="ggNum == 10"
            class="gg4"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_5.gif"
            v-else-if="ggNum == 11"
            class="gg5"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_6.gif"
            v-else-if="ggNum == 12"
            class="gg6"
          />
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/dy_gg.gif"
            v-else-if="ggNum == 13"
            class="gg6"
          />
        </div>
        <div class="light1">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/light.png"
          />
        </div>
        <div class="light2">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/light.png"
          />
        </div>

        <div class="banner">
          <!--banner_up -->

          <!-- gif图替换 -->
          <div ref="odiv">
            <div class="gif gg" v-if="ggNum == 1" ref="gif">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_1.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 2">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_2.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 3">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_3.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 4">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_4.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 5">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_5.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 6">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_6.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 14">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/dy_gg.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 15">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/gg1.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 16">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/gg2.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 17">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/gg3.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 18">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/gg4.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 19">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/gg1.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 20">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/gg2.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 21">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/gg3.gif"
              />
            </div>
            <div class="gif gg" v-else-if="ggNum == 22">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/gg4.gif"
              />
            </div>
            <div class="gif mm" v-if="mmNum == 1">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_1.gif"
              />
            </div>
            <div class="gif mm" v-else-if="mmNum == 2">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_2.gif"
              />
            </div>
            <div class="gif mm" v-else-if="mmNum == 3">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_3.gif"
              />
            </div>
            <div class="gif mm" v-else-if="mmNum == 4">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_4.gif"
              />
            </div>
            <div class="gif mm" v-else-if="mmNum == 5">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_5.gif"
              />
            </div>
            <div class="gif mm" v-else-if="mmNum == 6">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_6.gif"
              />
            </div>
            <div class="gif mm" v-else-if="mmNum == 14">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/dy_mm.gif"
              />
            </div>
            <div class="gif mm mmtop" v-else-if="mmNum == 15">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/mm1.gif"
              />
            </div>
            <div class="gif mm mmtop" v-else-if="mmNum == 16">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/mm2.gif"
              />
            </div>
            <div class="gif mm mmtop" v-else-if="mmNum == 17">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/mm3.gif"
              />
            </div>
            <div class="gif mm mmtop" v-else-if="mmNum == 18">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/mm4.gif"
              />
            </div>
            <div class="gif mm" v-else-if="mmNum == 19">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/mm1.gif"
              />
            </div>
            <div class="gif mm" v-else-if="mmNum == 20">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/mm2.gif"
              />
            </div>
            <div class="gif mm" v-else-if="mmNum == 21">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/mm3.gif"
              />
            </div>
            <div class="gif mm" v-else-if="mmNum == 22">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/mm4.gif"
              />
            </div>
            <div class="gif yr" v-if="yrNum == 1">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_1.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 2">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_2.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 3">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_3.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 4">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_4.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 5">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_5.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 6">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_6.gif"
              />
            </div>
            <div class="gif yr ggmin" v-else-if="yrNum == 14">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/dy_yr.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 15">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/yr1.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 16">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/yr2.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 17">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/yr3.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 18">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/yr4.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 19">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/yr1.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 20">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/yr2.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 21">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/yr3.gif"
              />
            </div>
            <div class="gif yr" v-else-if="yrNum == 22">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/yr4.gif"
              />
            </div>
          </div>
          <div
            class="videoLeft"
            :style="
              videoBg == 2
                ? 'width:13.5%'
                : videoBg == 3
                ? 'width:15%'
                : 'width:15%'
            "
          >
            <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/leftbox.png"
              v-if="videoBg == 1"
            />
            <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/leftbox2.png"
              v-else-if="videoBg == 2"
            />
            <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/leftbox3.png"
              v-else-if="videoBg == 3"
            />
            <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/leftbox4.png"
              v-else-if="videoBg == 4"
            />
            <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rightbox5.png"
              v-else-if="videoBg == 5"
            />
            <div class="videoAccount">
              <div
                class="videoin"
                :style="
                  videoBg == 2
                    ? 'margin-top:30%'
                    : videoBg == 3
                    ? 'margin-top:33%'
                    : 'margin-top:37%'
                "
              >
                <div class="title chinBold" v-if="decipherment">
                  {{rankingName}} 消耗了 {{rankingCaps}} 瓶盖是目前的榜一大哥！
                </div>
                <div class="title engM" v-else>
                  {{rankingName}} {{spand}} {{rankingCaps}} beer caps for hype goddd!
                </div>
                <div class="account">
                  <div class="icon">
                    <img :src="imgurl1" />
                  </div>
                  <div class="address">
                    <div>{{ nickname }}</div>
                    <div class="caps">
                      <img
                        src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/caps.png"
                      />
                      {{ bottleCap }}
                    </div>
                  </div>
                  <div class="btn" @click="returnBack">
                    <img
                      src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/return.png"
                    />
                    <div class="chinBold" v-if="decipherment">换人</div>
                    <div v-else class="engM">Change</div>
                  </div>
                </div>
                <div class="iconList" v-if="videoBg == 1">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon1.png"
                    class="img1"
                    @click="openseaLink"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon2.png"
                    class="img2"
                    @click="twitter"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon3.png"
                    class="img3"
                    @click="redirect"
                  />
                </div>
                <div class="iconList" v-else-if="videoBg == 2">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon1_2.png"
                    class="img4"
                    @click="openseaLink"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon2_2.png"
                    class="img5"
                    @click="twitter"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon3_2.png"
                    class="img6"
                    @click="redirect"
                  />
                </div>
                <div class="iconList" v-else-if="videoBg == 3">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon1_3.png"
                    class="img7"
                    @click="openseaLink"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon2_3.png"
                    class="img8"
                    @click="twitter"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon3_3.png"
                    class="img9"
                    @click="redirect"
                  />
                </div>
                <div class="iconList" v-else-if="videoBg == 4">
                  <img
                    src="/assets/icon/music/icon4_1.png"
                    class="img7"
                    @click="openseaLink"
                  />
                  <img
                    src="/assets/icon/music/icon4_2.png"
                    class="img8"
                    @click="twitter"
                  />
                  <img
                    src="/assets/icon/music/icon4_3.png"
                    class="img9"
                    @click="redirect"
                  />
                </div>
                <div class="iconList" v-else>
                  <img
                    src="/assets/icon/music/icon5_1.png"
                    class="img7"
                    @click="openseaLink"
                  />
                  <img
                    src="/assets/icon/music/icon5_2.png"
                    class="img8"
                    @click="twitter"
                  />
                  <img
                    src="/assets/icon/music/icon5_3.png"
                    class="img9"
                    @click="redirect"
                  />
                </div>
                <div class="musicBox">
                  <div class="photo">
                    <img :src="singerImg" />
                  </div>
                  <div class="sing">
                    <p class="singerName">{{ singerName }}</p>
                    <p class="singer">{{ singer }}</p>
                  </div>
                  <div class="play">
                    <img
                      src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/pause.png"
                      @click="onStartClick"
                      v-if="!start"
                    />
                    <img
                      src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/play.png"
                      @click="onEndClick"
                      v-else
                    />
                  </div>
                  <div class="arrowTip" v-if="decipherment && !arrowTip">
                    <img
                      src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/arrowChina.png"
                    />
                  </div>
                  <div class="arrowTip" v-else-if="!arrowTip">
                    <img
                      src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/arrowEn.png"
                    />
                  </div>
                </div>

                <div class="iconListup" v-if="videoBg == 1">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/action1.png"
                    class="img1"
                    @click="handle1"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/action2.png"
                    class="img2"
                    @click="handle2"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/action3.png"
                    class="img3"
                    @click="handle3"
                  />
                </div>
                <div class="iconListup" v-if="videoBg == 2">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/action1_2.png"
                    class="img1"
                    @click="handle1"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/action2_2.png"
                    class="img2"
                    @click="handle2"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/action3_2.png"
                    class="img3"
                    @click="handle3"
                  />
                </div>
                <div class="iconListup" v-if="videoBg == 3">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/bz/them3.png"
                    class="img7"
                    @click="handleBz"
                  />
                  <!-- <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/action2_3.png"
                    class="img8"
                    @click="handle2"
                  /> -->
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/action3_3.png"
                    class="img9"
                    @click="handle3"
                  />
                </div>
                <div class="iconListup" v-if="videoBg == 4">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/bz/them4.png"
                    class="img7"
                    @click="handleBz"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/action4.png"
                    class="img9"
                    @click="handle3"
                  />
                </div>
                <div class="iconListup" v-if="videoBg == 5">
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/gif/bz/them5.png"
                    class="img7"
                    @click="handleBz"
                  />
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/action5.png"
                    class="img9"
                    @click="handle3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            class="videoRight"
            :style="
              videoBg == 2
                ? 'width:13.5%'
                : videoBg == 3 || videoBg == 4 || videoBg == 5
                ? 'width:15%'
                : ''
            "
          >
            <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rightbox.png"
              v-if="videoBg == 1"
            />
            <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rightbox2.png"
              v-else-if="videoBg == 2"
            />
            <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rightbox3.png"
              v-else-if="videoBg == 3"
            />
            <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rightbox4.png"
              v-else-if="videoBg == 4"
            />
            <img
              src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/leftbox5.png"
              v-else-if="videoBg == 5"
            />
            <div
              class="videoDan"
              :style="
                videoBg == 2
                  ? 'margin-top:5%'
                  : videoBg == 3
                  ? 'margin-top:8%'
                  : 'margin-top:13%'
              "
            >
              <div class="videoDanin">
                <div class="title chinBold" v-if="decipherment">
                  内场公告 ({{ online_num }}）
                </div>
                <div class="title engM" v-else>
                  Bulletin Board（{{ online_num }}）
                </div>
                <div class="scrollbox" id="scrollbox">
                  <div
                    class="list"
                    v-for="(item, index) in boxArr"
                    :key="index"
                  >
                    <div
                      class="oimg"
                      :class="[item.u_id == u_id ? 'bgShadow' : '']"
                    >
                      <img :src="item.url" />
                    </div>
                    <div class="cont">
                      <div
                        class="address"
                        :class="[item.u_id == u_id ? 'colorfont' : '']"
                      >
                        {{ item.contract_address }}
                      </div>
                      <div class="detail">
                        {{ item.message }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="box" ref="box">
          <!--banner_up -->
          <!-- <video autoplay loop muted width="100%" ref="videoDown">
            <source :src="videoDsrc" type="video/mp4" />
          </video> -->
          <div class="boxin">
            <div class="boxinin">
              <p id="wrap"></p>
            </div>
          </div>

          <!-- <div class="rythm radius2"></div> -->
        </div>
        <div class="message" ref="message" @click="handlemessage">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/message.png"
          />
          <div class="messagein">
            <div class="tx">
              <img :src="imgurl1" />
            </div>
            <div class="send">
              <div class="sendAddress">
                {{ nickname }}
              </div>
              <div class="sendInpt">
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/send.png"
                />
                <input type="text" @click.stop="stopstrans" v-model="val" />
              </div>
            </div>
            <div class="sendbtn">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/sendbtn.png"
              />
              <div
                class="btnin chinBold"
                @click.stop="send"
                v-if="decipherment"
              >
                发送
              </div>
              <div class="btnin engM" @click.stop="send" v-else>PUBLISH</div>
            </div>
            <div class="icon">
              <div
                class="rocket"
                @mouseover="rainmouseover(1)"
                @mouseleave="rainmouseleave"
                @click.stop="giftGif(1)"
              >
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rockt4.png"
                  v-if="videoBg == 4"
                />
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rockt3.png"
                  v-else-if="videoBg == 3"
                />
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rockt5.png"
                  v-else
                />
              </div>
              <div
                class="rain"
                @mouseover="rainmouseover(2)"
                @mouseleave="rainmouseleave"
                @click.stop="giftGif(2)"
              >
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rain.png"
                  v-if="videoBg == 4"
                />
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rainhover.png"
                  v-else-if="videoBg == 3"
                />
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rain4.png"
                  v-else
                />
              </div>
              <div
                class="rain"
                @mouseover="rainmouseover(3)"
                @mouseleave="rainmouseleave"
                @click.stop="giftGif(300)"
              >
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/fireicon.png"
                  v-if="videoBg == 4"
                />
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/fireicon3.png"
                  v-else-if="videoBg == 3"
                />
                <img
                  src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/fireicon4.png"
                  v-else
                />
              </div>
            </div>
          </div>
          <div class="messagebox" v-show="messageTip">
            <div class="message_icon">
              <img :src="tanImg" />
              <!-- <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rainhover.png" v-else /> -->
            </div>
            <div class="main">
              <div class="title chinBold" v-if="decipherment">
                {{ iconTitle }}
              </div>
              <div class="title engM" v-else>{{ iconTitle_en }}</div>
              <div class="op chinBold" v-if="decipherment">
                {{ iconTip }}
                <p>
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/caps.png"
                  />{{ iconNum }}
                </p>
              </div>
              <div class="op engM" v-else>
                {{ iconTip_en }}
                <p>
                  <img
                    src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/caps.png"
                  />{{ iconNum }}
                </p>
              </div>
            </div>
          </div>
          <div class="messagebox" v-show="errorTipShow">
            <div class="message_icon">
              <img :src="tanImg" />
              <!-- <img src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/rainhover.png" v-else /> -->
            </div>
            <div class="main">
              <div class="op chinBold" v-if="decipherment">{{ errorTip }}</div>
              <div class="op engM" v-else>{{ errorTip_en }}</div>
            </div>
          </div>
        </div>
        <div class="hongqi" v-if="hongqi">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/hq.png"
            alt=""
          />
        </div>
      </div>
    </div>
    <div class="m_page">
      <img
        src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/mbg.png"
      />
      <div class="centerbox">
        <div class="tan">
          <img
            src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/m_box.png"
          />
          <div class="contxt">
            <p class="chinBold" v-if="decipherment">Party只在PC端举行 ！</p>
            <p class="engM" v-else>PC Only ！</p>
            <div class="btn" @click="goback">
              <img
                src="https://img.ohdat.io/peking_monsters/website/assets/icon/music/m_btn.png"
              />
              <div class="txt chinBold">Back to Mainpage</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// console.log(process.env.NODE_ENV);
let ws = "";
if (process.env.NODE_ENV == "development") {
  ws = new WebSocketV2("wss://peking-monsters-party.dev.xxjio.com/ws");
} else {
  ws = new WebSocketV2("wss://peking-monsters-party.pekingmonsters.xyz/ws");
}
// let ws = new WebSocketV2("wss://peking-monsters-party.dev.xxjio.com/ws");
import axios from "axios";
import { Message } from "element-ui";
import AgoraRTC from "agora-rtc-sdk-ng";

let client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

import Rythm from "rythm.js";

const rythm = new Rythm();
console.log(rythm);

import { connectWallet, getCurrentAccount } from "@/utils/login.js";
// import domtoimage from "dom-to-image";
import {
  chat,
  login,
  initial,
  timeOut,
  fetch_rtc_token,
  opensea,
  balance_of,
  shower_gifts,
  gift_rank
} from "@/api/peking/index.js";
import { listNft } from "@/api/collection/index.js";
import { WebSocketV2 } from "@ohdat/common";

export default {
  name: "Music",
  data() {
    return {
      decipherment: true,
      lang: "",
      chin: "中文",
      eng: "ENGLISH",
      list: [],
      musicUrl: "",
      singerName: "",
      singer: "",
      singerImg: "",
      i: "",
      roadDom: "",
      meteorDom: "",
      clientWidth: document.documentElement.clientWidth,
      maxCount: 50,
      start: false,
      walletAddress: "",
      currentAccounttext: "",
      len: false,
      val: "",
      imgurl: "",
      imgurl1: "",
      tokenId: "",
      u_id: "",
      contractAddress: "",
      loveArr: [
        //L
        { size: 30, x: 40, y: 0 },
        { size: 40, x: 40, y: 30 },
        { size: 40, x: 40, y: 60 },
        { size: 30, x: 40, y: 90 },
        { size: 30, x: 40, y: 120 },
        { size: 30, x: 40, y: 150 },
        { size: 20, x: 40, y: 180 },
        { size: 30, x: 40, y: 210 },
        { size: 30, x: 40, y: 240 },
        { size: 30, x: 40, y: 270 },
        { size: 30, x: 70, y: 270 },
        { size: 20, x: 100, y: 270 },
        { size: 40, x: 130, y: 270 },
        { size: 30, x: 160, y: 270 },
        { size: 30, x: 190, y: 270 },
        //O
        { size: 40, x: 270, y: 30 },
        { size: 20, x: 270, y: 60 },
        { size: 20, x: 270, y: 90 },
        { size: 20, x: 270, y: 120 },
        { size: 40, x: 270, y: 150 },
        { size: 20, x: 270, y: 180 },
        { size: 20, x: 270, y: 210 },
        { size: 20, x: 270, y: 240 },
        { size: 40, x: 270, y: 270 },
        { size: 30, x: 300, y: 270 },
        { size: 40, x: 330, y: 270 },
        { size: 20, x: 360, y: 270 },
        { size: 40, x: 390, y: 270 },
        { size: 20, x: 420, y: 270 },
        { size: 40, x: 420, y: 240 },
        { size: 20, x: 420, y: 210 },
        { size: 30, x: 420, y: 180 },
        { size: 20, x: 420, y: 150 },
        { size: 40, x: 420, y: 120 },
        { size: 40, x: 420, y: 90 },
        { size: 40, x: 420, y: 60 },
        { size: 30, x: 420, y: 30 },
        { size: 20, x: 390, y: 30 },
        { size: 40, x: 360, y: 30 },
        { size: 40, x: 330, y: 30 },
        { size: 20, x: 300, y: 30 },
        { size: 40, x: 270, y: 30 },
        { size: 40, x: 500, y: 30 },
        { size: 30, x: 510, y: 60 },
        { size: 20, x: 520, y: 90 },
        { size: 30, x: 530, y: 120 },
        { size: 30, x: 540, y: 150 },
        { size: 30, x: 550, y: 180 },
        { size: 40, x: 560, y: 210 },
        { size: 20, x: 570, y: 240 },
        { size: 20, x: 580, y: 270 },
        { size: 40, x: 590, y: 240 },
        { size: 20, x: 600, y: 210 },
        { size: 20, x: 610, y: 180 },
        { size: 30, x: 620, y: 150 },
        { size: 30, x: 630, y: 120 },
        { size: 30, x: 640, y: 90 },
        { size: 30, x: 650, y: 60 },
        { size: 30, x: 660, y: 30 },
        { size: 30, x: 740, y: 30 },
        { size: 20, x: 740, y: 60 },
        { size: 20, x: 740, y: 90 },
        { size: 40, x: 740, y: 120 },
        { size: 40, x: 740, y: 150 },
        { size: 20, x: 740, y: 180 },
        { size: 30, x: 740, y: 210 },
        { size: 40, x: 740, y: 240 },
        { size: 40, x: 740, y: 270 },
        { size: 40, x: 770, y: 270 },
        { size: 30, x: 800, y: 270 },
        { size: 30, x: 830, y: 270 },
        { size: 30, x: 860, y: 270 },
        { size: 30, x: 890, y: 270 },
        { size: 20, x: 770, y: 30 },
        { size: 20, x: 800, y: 30 },
        { size: 30, x: 830, y: 30 },
        { size: 30, x: 860, y: 30 },
        { size: 30, x: 890, y: 30 },
        { size: 30, x: 770, y: 150 },
        { size: 30, x: 800, y: 150 },
        { size: 30, x: 830, y: 150 },
        { size: 20, x: 860, y: 150 },
        { size: 40, x: 890, y: 150 },
      ],
      squarenessArr: [
        { size: 30, x: 1, y: 200, num: 0 },
        { size: 30, x: 30, y: 200, num: 0 },
        { size: 40, x: 60, y: 200, num: 0 },
        { size: 40, x: 100, y: 200, num: 0 },
        { size: 40, x: 140, y: 200, num: 0 },
        { size: 30, x: 180, y: 200, num: 0 },
        { size: 30, x: 220, y: 200, num: 0 },
        { size: 40, x: 260, y: 200, num: 0 },
        { size: 40, x: 300, y: 200, num: 0 },
        { size: 30, x: 340, y: 200, num: 0 },
      ],
      squarenessArrCO: [
        { size: 30, x: 1, y: 200, num: 0 },
        { size: 30, x: 30, y: 200, num: 0 },
        { size: 40, x: 60, y: 200, num: 0 },
        { size: 40, x: 100, y: 200, num: 0 },
        { size: 40, x: 140, y: 200, num: 0 },
        { size: 30, x: 180, y: 200, num: 0 },
        { size: 30, x: 220, y: 200, num: 0 },
        { size: 40, x: 260, y: 200, num: 0 },
        { size: 40, x: 300, y: 200, num: 0 },
        { size: 30, x: 340, y: 200, num: 0 },
      ],
      roadArr: [
        { size: 30, x: 1, y: 200 },
        { size: 30, x: 30, y: 200 },
        { size: 40, x: 60, y: 200 },
        { size: 40, x: 100, y: 200 },
        { size: 40, x: 140, y: 200 },
        { size: 30, x: 180, y: 200 },
        { size: 30, x: 220, y: 200 },
        { size: 40, x: 260, y: 200 },
        { size: 40, x: 300, y: 200 },
        { size: 30, x: 340, y: 200 },
      ],
      pyramArr: [
        { size: 50, x: 470, y: 35 },

        { size: 30, x: 430, y: 70 },
        { size: 20, x: 450, y: 70 },
        { size: 30, x: 470, y: 70 },
        { size: 40, x: 490, y: 70 },
        { size: 40, x: 510, y: 70 },

        { size: 30, x: 410, y: 95 },
        { size: 20, x: 430, y: 95 },
        { size: 30, x: 450, y: 95 },
        { size: 30, x: 470, y: 95 },
        { size: 30, x: 490, y: 95 },
        { size: 30, x: 510, y: 95 },
        { size: 30, x: 530, y: 95 },

        { size: 30, x: 390, y: 120 },
        { size: 30, x: 410, y: 120 },
        { size: 30, x: 430, y: 120 },
        { size: 20, x: 450, y: 120 },
        { size: 20, x: 470, y: 120 },
        { size: 40, x: 490, y: 120 },
        { size: 40, x: 510, y: 120 },
        { size: 20, x: 530, y: 120 },
        { size: 30, x: 550, y: 120 },

        { size: 40, x: 370, y: 145 },
        { size: 30, x: 390, y: 145 },
        { size: 30, x: 410, y: 145 },
        { size: 30, x: 430, y: 145 },
        { size: 20, x: 450, y: 145 },
        { size: 20, x: 470, y: 145 },
        { size: 40, x: 490, y: 145 },
        { size: 40, x: 510, y: 145 },
        { size: 20, x: 530, y: 145 },
        { size: 30, x: 550, y: 145 },
        { size: 40, x: 570, y: 145 },

        { size: 40, x: 350, y: 170 },
        { size: 40, x: 370, y: 170 },
        { size: 30, x: 390, y: 170 },
        { size: 30, x: 410, y: 170 },
        { size: 30, x: 430, y: 170 },
        { size: 20, x: 450, y: 170 },
        { size: 20, x: 470, y: 170 },
        { size: 40, x: 490, y: 170 },
        { size: 40, x: 510, y: 170 },
        { size: 20, x: 530, y: 170 },
        { size: 30, x: 550, y: 170 },
        { size: 40, x: 570, y: 170 },
        { size: 40, x: 590, y: 170 },

        { size: 30, x: 330, y: 195 },
        { size: 40, x: 350, y: 195 },
        { size: 40, x: 370, y: 195 },
        { size: 30, x: 390, y: 195 },
        { size: 30, x: 410, y: 195 },
        { size: 30, x: 430, y: 195 },
        { size: 20, x: 450, y: 195 },
        { size: 30, x: 470, y: 195 },
        { size: 30, x: 490, y: 195 },
        { size: 30, x: 510, y: 195 },
        { size: 30, x: 530, y: 195 },
        { size: 30, x: 550, y: 195 },
        { size: 40, x: 570, y: 195 },
        { size: 40, x: 590, y: 195 },
        { size: 40, x: 610, y: 195 },

        { size: 30, x: 320, y: 220 },
        { size: 40, x: 340, y: 220 },
        { size: 40, x: 350, y: 220 },
        { size: 40, x: 370, y: 220 },
        { size: 30, x: 390, y: 220 },
        { size: 30, x: 410, y: 220 },
        { size: 30, x: 430, y: 220 },
        { size: 20, x: 450, y: 220 },
        { size: 30, x: 470, y: 220 },
        { size: 30, x: 490, y: 220 },
        { size: 30, x: 510, y: 220 },
        { size: 30, x: 530, y: 220 },
        { size: 30, x: 550, y: 220 },
        { size: 40, x: 570, y: 220 },
        { size: 40, x: 590, y: 220 },
        { size: 20, x: 610, y: 220 },
        { size: 30, x: 630, y: 220 },
        { size: 20, x: 645, y: 220 },

        { size: 30, x: 280, y: 245 },
        { size: 30, x: 290, y: 245 },
        { size: 30, x: 310, y: 245 },
        { size: 40, x: 330, y: 245 },
        { size: 40, x: 340, y: 245 },
        { size: 40, x: 360, y: 245 },
        { size: 30, x: 380, y: 245 },
        { size: 30, x: 400, y: 245 },
        { size: 30, x: 420, y: 245 },
        { size: 20, x: 440, y: 245 },
        { size: 30, x: 460, y: 245 },
        { size: 30, x: 480, y: 245 },
        { size: 30, x: 500, y: 245 },
        { size: 30, x: 520, y: 245 },
        { size: 30, x: 540, y: 245 },
        { size: 40, x: 560, y: 245 },
        { size: 40, x: 580, y: 245 },
        { size: 20, x: 600, y: 245 },
        { size: 30, x: 620, y: 245 },
        { size: 30, x: 640, y: 245 },
        { size: 30, x: 660, y: 245 },
      ],
      randomArr: [],
      balls: [],
      timer: 20,
      leftNum: 25, //——10
      rightNum: 25,
      min: -57, //-20
      max: 0, //40
      xlist: [],
      ylist: [],
      repeat: [],
      hongqi: false,
      num: 1,
      sqTimer: 100,
      roadtimes: 100,
      boxArr: [],
      musicStart: 0,
      loginWallet: false, //是否登录状态
      haveTicket: false, //是否有票加入,
      headarImg: "",
      headarImgMefers: "",
      headarImgdoge: "",
      headarImgdudu: "",
      selected: false,
      oimgIndex: 0,
      mefersSelIndex: 0,
      dogeSelIndex: 0,
      duduSelIndex: 0,
      videoBg: 3,
      videoup: 1,
      online_num: "",
      options: {
        appid: "9317c619c9f043efa74cf0c334fa5de9",
        channel: "test1",
        uid: Math.floor(Math.random() * 100000),
        token:
          "007eJxTYJjfJHTj1narYgMP7kXnGhK6VbW3xFip1h8TiZTYILpFYqECg6WxoXmymaFlsmWagYlxalqiuUlymkGysbFJWqJpSqrlsxtKyWlaKsnbVbcwMzJAIIjPylCSWlxiyMAAANpZHpw=",
      },
      endTime: "",
      time: "",
      page: 1,
      pageMefers: 1,
      pagedoge: 1,
      pagedudu: 1,
      busy: false,
      flyNum: 2000,
      flyTotal: 6000,
      videoNum: 0,
      arr: [],
      arrMefers: [],
      arrdoge: [],
      arrdudu: [],
      loading: false,
      loadingMefers: false,
      loadingdoge: false,
      loadingdudu: false,
      flag: true,
      flagdudu: false,
      flagMefers: false,
      flagdoge: false,
      agora: "",
      audioTrack: [],
      volume: "",
      audioVolume: 200,
      active: true,
      content: "",
      ggNum: 13,
      mmNum: 13,
      yrNum: 13,
      transform: 0,
      videoSrc:
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/1.2.mp4",
      videoDsrc:
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n3.mp4",
      nickname: "",
      sfsfrsv: [
        { size: 30, x: 1, y: 200 },
        { size: 30, x: 30, y: 200 },
        { size: 40, x: 60, y: 200 },
        { size: 40, x: 100, y: 200 },
        { size: 40, x: 140, y: 200 },
        { size: 30, x: 180, y: 200 },
        { size: 30, x: 220, y: 200 },
        { size: 40, x: 260, y: 200 },
        { size: 40, x: 300, y: 200 },
        { size: 30, x: 340, y: 200 },
      ],
      transformSrc: "",
      clear: "",
      clear1: "",
      clear2: "",
      myGif:
        "url('https://img.ohdat.io/peking_monsters/website/assets/icon/1.png')",
      wsSend: "",
      action: "",
      musicOn: false,
      start_time: "",
      inputAddress: "",
      haveAll: true,
      posterBg: "",
      arrowTip: false,
      playing: false,
      posterTransform: "",
      moveFlag: true,
      moveStop: true,
      party_token: "", //party币
      bottleCap: "", //瓶盖
      rainThem3: false,
      rainThem4: false,
      rainThem5: false,
      capsThem3: false,
      capsThem4: false,
      capsThem5: false,
      messageTip: false,
      errorTipShow: false,
      rocketThem3: false,
      rocketGoing:false,
      rocketThem4: false,
      rocketThem5: false,
      rocketName: "", //火箭名称
      tanImg: "",
      itemslist: ["PM", "MFERS", "DogeClub", "DuDu"],
      selTabIndex: 0,
      fire1: false,
      fire2: false,
      fire3: false,
      fire4: false,
      fire5: false,
      fire6: false,
      fire7: false,
      fire8: false,
      showNft: false,
      holderNft: "Peking Monsters",
      transformNew: 1,
      iconTitle: "",
      iconTitle_en: "",
      iconTip: "",
      iconTip_en: "",
      errorTip: "",
      errorTip_en: "",
      iconNum: "",
      moveflag: true,
      rankingName:"Who",
      spand:"spends",
      rankingCaps:"",
    };
  },

  watch: {
    decipherment: function (oldValue) {
      // this.revise_text= false;
      if (oldValue == true) {
        this.chin = "中文";
        this.eng = "ENGLISH";
      } else {
        this.chin = "ENGLISH";
        this.eng = "中文";
      }
    },
  },
  created() {
    this.createdStart();
  },
  mounted() {
    // window.tttt = this;
    // console.log("gggggggg", this);
    //中英文切换
    if (this.$route.params.decipherment) {
      this.decipherment = true;
    } else {
      this.decipherment = false;
    }

    this.ws();
    AgoraRTC.onAutoplayFailed = () => {
      alert("click to start autoplay!");
    };
    this.watchWeb3Event();
    this.initial();
    this.checkJoin();
    this.checkLogIn();
    this.musicDance();
    this.timeOut();
    this.preload2();
    this.gift_rank();
    //海龟，鲸鱼等动画
    // let flyTimer = setInterval(() => {
    //   this.fn();
    // }, this.flyTotal)
    let that = this;
    document.onkeydown = function (e) {
      e = window.event || e;
      //保证是在登录页面发出的enter事件
      if (e.code === "Enter" || e.code === "enter") {
        //调用登录函数
        that.send();
      }
    };
  },
  methods: {
    async gift_rank(){
      let data = await gift_rank();
      console.log(data)
      if(data.data != null){
        this.rankingName = data.data[0].name ;
        this.spand = 'spent'
        this.rankingCaps = data.data[0].score;
      }
    },
    preload2() {
      let imgs = [
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/no_open.jpeg",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/bg.jpeg",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/pause.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/play.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/no_login.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/no.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/have_tick.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/heart.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/cenBox.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/connect1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/box1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/box2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/box3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/box4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/select.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/connect.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/no_open.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/join.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/no_tick.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/please.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform2_new.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform3_new.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform4_new.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform5_new.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rain/them3_rain.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rain/them4_rain.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rain/them5_rain.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/caps/them3_caps.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/caps/them4_caps.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/caps/them5_caps.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rocket/them3_rocket.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rocket/them4_rocket.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/rocket/them5_rocket.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/2.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/3.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/4.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/5.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/6.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/7.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/fire/8.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_2.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_3.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_4.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_5.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/yr_6.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/dy_yr.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_2.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_3.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_4.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_5.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/mm_6.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/dy_mm.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_2.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_3.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_4.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_5.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/gg_6.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/dy_gg.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/light.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/gg1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/gg2.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/gg3.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/gg4.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/gg1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/gg2.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/gg3.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/gg4.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/mm1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/mm2.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/mm3.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/mm4.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/mm1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/mm2.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/mm3.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/mm4.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/yr1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/yr2.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/yr3.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them4/yr4.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/yr1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/yr2.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/yr3.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/role/them5/yr4.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/leftbox.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/leftbox2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/leftbox3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/leftbox4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rightbox5.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/caps.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/return.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon1_2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon2_2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon3_2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon1_3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon2_3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon3_3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon4_1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon4_2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon4_3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon5_1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon5_2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/icon5_3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/arrowChina.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/arrowEn.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/action1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/action2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/action3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/action1_2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/action2_2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/action3_2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/bz/them3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/action2_3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/action3_3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/bz/them4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/action4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/gif/bz/them5.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/action5.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rightbox.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rightbox2.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rightbox3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rightbox4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/leftbox5.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/message.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/send.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/sendbtn.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rockt4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rockt3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rockt5.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rain.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rainhover.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rain4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/fireicon.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/fireicon3.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/fireicon4.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/hq.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/mbg.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/m_box.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/m_btn.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/1.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n3.jpeg",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n4.jpeg",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n5.jpeg",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/gg_pp.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/mm_pp.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/yr_pp.png",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them3/mm/mm1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them3/gg/gg1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them3/yr/yr1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them3/mfers/mfers1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them3/doge/doge1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them3/dudu/dudu1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them3/mm.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them3/gg.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them3/yr.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them3/mfers.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them3/doge.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them3/dudu.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them4/mm/mm1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them4/gg/gg1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them4/yr/yr1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them4/mfers/mfers1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them4/doge/doge1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them4/dudu/dudu1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them4/mm.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them4/gg.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them4/yr.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them4/mfers.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them4/doge.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them4/dudu.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them5/mm/mm1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them5/gg/gg1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them5/yr/yr1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them5/mfers/mfers1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them5/doge/doge1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them5/dudu/dudu1.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them5/mm.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them5/gg.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them5/yr.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them5/mfers.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them5/doge.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them5/dudu.gif",
        "https://img.ohdat.io/peking_monsters/website/assets/icon/music/qp2.png",
      ];
      for (let img of imgs) {
        let image = new Image();
        image.src = img;
        image.onload = () => {
          this.preloadCount++;
          // 计算图片加载的百分数，绑定到percent变量
          let percentNum = Math.floor((this.preloadCount / 14) * 100);
          this.percent = `${percentNum}%`;
        };
      }
    },
    seltabList(index) {
      this.selTabIndex = index;
      if (index == 0) {
        this.oimgIndex = 0;
        this.arr = [];
        this.headarImg = [];
        this.holderNft = "Peking Monster", 
        this.page = 1;
        this.opensea();
      } else if (index == 1) {
        this.mefersSelIndex = 0;
        this.arrMefers = [];
        this.headarImgMefers = [];
        this.pageMefers = 1;
        this.holderNft = "MFER";
        this.mefersNft();
      } else if (index == 2) {
        this.dogeSelIndex = 0;
        this.arrdoge = [];
        this.headarImgdoge = [];
        this.holderNft = "Doge";
        this.pagedoge = 1;
        this.dogeNft();
      } else if (index == 3) {
        this.duduSelIndex = 0;
        this.arrdudu = [];
        this.headarImgdudu = [];
        this.duduNft();
        this.pagedudu = 1;
        this.holderNft = "Dudu";
      }
      // if (this.$refs.peopleList.scrollTop > 0) {
      //   this.$refs.peopleList.scrollTop = 0;
      // }
    },
    async giftGif(num) {
      var that = this;
      var bottleCap = Number(that.bottleCap);
      var eType = "";
      if (num == 300) {
        eType = Math.floor(Math.random() * (307 - 300) + 300);
      } else {
        eType = num;
      }
      console.log(eType);
      that.messageTip = false;
      const params = {
        e_type: eType,
        phrase: "",
      };
      if (num == 1) {
        //火箭
        if (bottleCap > 2000) {
          let data = await shower_gifts(params);
          that.bottleCap = data.data.balance.caps;
        } else {
          that.errorTipShow = true;
          that.errorTip = "滚子，没瓶盖儿了。。。";
          that.errorTip_en = "Not enough Beer Caps";
          setTimeout(() => {
            that.errorTipShow = false;
          }, 2000);
        }
      } else if (num == 2) {
        //病毒雨
        if (bottleCap > 800) {
          // that.rainSars();
          let data = await shower_gifts(params);
          that.bottleCap = data.data.balance.caps;
        } else {
          that.errorTipShow = true;
          that.errorTip = "滚子，没瓶盖儿了。。。";
          that.errorTip_en = "Not enough Beer Caps";
          setTimeout(() => {
            that.errorTipShow = false;
          }, 2000);
        }
      } else {
        //冷焰火
        if (bottleCap > 300) {
          // that.rainSars();
          let data = await shower_gifts(params);
          that.bottleCap = data.data.balance.caps;
        } else {
          that.errorTipShow = true;
          that.errorTip = "滚子，没瓶盖儿了。。。";
          that.errorTip_en = "Not enough Beer Caps";
          setTimeout(() => {
            that.errorTipShow = false;
          }, 2000);
        }
      }
    },
    //冷焰火
    fireShow(eType) {
      var timer = 5000;
      if (eType == 300) {
        this.fire1 = true;
        setTimeout(() => {
          this.fire1 = false;
        }, timer);
      } else if (eType == 301) {
        this.fire2 = true;
        setTimeout(() => {
          this.fire2 = false;
        }, timer);
      } else if (eType == 302) {
        this.fire3 = true;
        setTimeout(() => {
          this.fire3 = false;
        }, timer);
      } else if (eType == 303) {
        this.fire4 = true;
        setTimeout(() => {
          this.fire4 = false;
        }, timer);
      } else if (eType == 304) {
        this.fire5 = true;
        setTimeout(() => {
          this.fire5 = false;
        }, timer);
      } else if (eType == 305) {
        this.fire6 = true;
        setTimeout(() => {
          this.fire6 = false;
        }, timer);
      } else if (eType == 306) {
        this.fire7 = true;
        setTimeout(() => {
          this.fire7 = false;
        }, timer);
      } else if (eType == 307) {
        this.fire8 = true;
        setTimeout(() => {
          this.fire8 = false;
        }, timer);
      }
    },
    //火箭
    rocketgift(inputName) {
      if (this.rocketGoing){
        setTimeout(() => {
          this.rocketgift(inputName)
        }, 10000);
        return
      }
      this.rocketGoing = true;
      this.rocketName = inputName;
      if (this.videoBg == 3) {
        this.rocketThem3 = true;
        setTimeout(() => {
          this.rocketThem3 = false;
          this.rocketGoing = false;
        }, 10000);
      } else if (this.videoBg == 4) {
        this.rocketThem4 = true;
        setTimeout(() => {
          this.rocketThem4 = false;
          this.rocketGoing = false;
        }, 10000);
      } else {
        this.rocketThem5 = true;
        setTimeout(() => {
          this.rocketThem5 = false;
          this.rocketGoing = false;
        }, 10000);
      }
    },
    //病毒雨
    rainSars() {
      if (this.videoBg == 3) {
        this.rainThem3 = true;
        setTimeout(() => {
          this.rainThem3 = false;
        }, 7500);
      } else if (this.videoBg == 4) {
        this.rainThem4 = true;
        setTimeout(() => {
          this.rainThem4 = false;
        }, 3000);
      } else {
        this.rainThem5 = true;
        setTimeout(() => {
          this.rainThem5 = false;
        }, 3500);
      }
    },
    ws() {
      let that = this;
      ws.onLogin((mes) => {
        // console.log(that.randomArr);
        if (!that.playing) {
          that.initial();
        }
      });
      ws.onMessage((mes) => {
        that.getMessage(mes);
        let opbox = document.getElementById("wrap");
        let oli = opbox.querySelectorAll("div");
        for (let i = 0; i < oli.length; i++) {
          if (oli[i].getAttribute("id") == mes.u_id) {
            that.sendMes(oli[i], mes.message);
          }
        }
      });
      ws.onSwitchMusic((res) => {
        let data = JSON.parse(res);
        // console.log(88888)
        console.log(data);
        let result = data.music;
        let domain = result.url.split("/");
        let host = window.location.host;
        let link = result.url.substr(
          result.url.indexOf(domain[2]) + domain[2].length
        );
        // alert(host)
        if (domain[2] != host) {
          that.musicUrl = "/proxy_peking" + link;
        } else {
          that.musicUrl = result.url;
        }
        that.singerName = result.name;
        that.singer = result.singer;
        that.singerImg = result.album_url;
        that.start_time = data.start_time;
        // that.videoSrc = result.video;//视频对应
        rythm.stop();
        rythm.setMusic("");
        // console.log(rythm.player.audio.currentTime)
        setTimeout(() => {
          if (that.start) {
            that.musicOn = false;
            that.onStartClick();
          }
        }, 100);
        that.$refs.transform.style.display = "none";
        that.videoBg = result.theme;
        let numGif = Math.floor(Math.random() * 2 + 1);
        let ops = document.getElementById("wrap");
        let list = ops.querySelectorAll("div");

        for (let i = 0; i < list.length; i++) {
          list[i].querySelector("img").src = that.randomGenerateImage(
            result.theme,
            list[i].getAttribute("catalog")
          );
        }
        if (result.theme == 3) {
          that.$refs.odiv.style.display = "block";
          that.$refs.transform.style.display = "none";
          localStorage.setItem("THEME", result.theme);
        }
        //转场动画
        if (localStorage.getItem("THEME") != 2 && result.theme == 2) {
          that.$refs.odiv.style.display = "block";
          that.$refs.transform.style.display = "block";
          that.transformNew = 2;
          // that.posterTransform =
          //   "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n1.jpeg";
          // that.$refs.transform.src =
          //   "https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform3.mp4";
          localStorage.setItem("THEME", result.theme);
          // that.$refs.transform.play();
          setTimeout(() => {
            that.$refs.transform.style.display = "none";
          }, 4000);
          // that.$refs.transform.addEventListener("ended", function () {
          //   that.$refs.transform.style.display = "none";
          // });
        }
        if (localStorage.getItem("THEME") != 3 && result.theme == 3) {
          that.$refs.transform.style.display = "block";
          that.transformNew = 3;
          // that.posterTransform =
          //   "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n2.jpeg";
          // that.$refs.transform.src =
          //   "https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform2.mp4";
          // that.$refs.transform.play();
          localStorage.setItem("THEME", result.theme);
          setTimeout(() => {
            that.$refs.transform.style.display = "none";
          }, 4000);
          // that.$refs.transform.addEventListener("ended", function () {
          //   that.$refs.transform.style.display = "none";
          // });
        }

        if (localStorage.getItem("THEME") != 4 && result.theme == 4) {
          that.$refs.transform.style.display = "block";
          that.transformNew = 4;
          // that.posterTransform = "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n3.jpeg";
          // that.$refs.transform.src = "https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform4.mp4";
          // that.$refs.transform.play();
          localStorage.setItem("THEME", result.theme);
          setTimeout(() => {
            that.$refs.transform.style.display = "none";
          }, 3000);
          // that.$refs.transform.addEventListener("ended", function () {
          //   that.$refs.transform.style.display = "none";
          // });
        }

        if (localStorage.getItem("THEME") != 5 && result.theme == 5) {
          that.$refs.transform.style.display = "block";
          that.transformNew = 5;
          // that.posterTransform =
          //   "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n4.jpeg";
          // that.$refs.transform.src = "https://img.ohdat.io/peking_monsters/website/assets/icon/music/transform5.mp4";
          // that.$refs.transform.play();
          localStorage.setItem("THEME", result.theme);
          setTimeout(() => {
            that.$refs.transform.style.display = "none";
          }, 5000);
          // that.$refs.transform.addEventListener("ended", function () {
          //   that.$refs.transform.style.display = "none";
          // });
        }
        //主题背景
        if (result.theme == 1) {
          that.posterBg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n1.jpeg";
          that.$refs.videoDown.src =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n1.mp4";
        } else if (result.theme == 2) {
          that.posterBg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n2.jpeg";
          that.$refs.videoDown.src =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n2.mp4";
        } else if (result.theme == 3) {
          that.posterBg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n3.jpeg";
          that.$refs.videoDown.src =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n3.mp4";
        } else if (result.theme == 4) {
          that.posterBg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n4.jpeg";
          that.$refs.videoDown.src =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n4.mp4";
        } else if (result.theme == 5) {
          that.posterBg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n5.jpeg";
          that.$refs.videoDown.src =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/mp4/n5.mp4";
        }
      });

      ws.onOnlineNum((res) => {
        // console.log(res)
        let result = JSON.parse(res);
        that.online_num = result.online_num;
      });
      // 互动
      ws.onSolitaire((res) => {
        that.hongqi = false;
        that.playing = true;
        that.moveStop = false;
        console.log(that.moveStop);
        if (res == 1) {
          setTimeout(() => {
            that.hongqi = true;
          }, 2500);
          // that.hongqi = true;
          // 1.金字塔;
          that.pyramid();
        } else if (res == 2) {
          // 2.蛇形开火车
          that.roadArrness();
          setTimeout(() => {
            that.road();
          }, 2000);
        } else if (res == 3) {
          // 3.左右对撞
          that.randomMoveAfterSeparate();
        } else if (res == 4) {
          // 4.乱撞
          that.randomMoveAll();
        } else if (res == 5) {
          // 5.LOVE
          that.lovely();
        } else if (res == 6) {
          // 6.方形开火车
          that.squareness();
          setTimeout(() => {
            that.go();
          }, 2500);
        } else if (res == 7) {
          that.punch();
        } else {
          that.wsRest();
        }
      });
      //gif切换角色
      ws.onAvatarAction((res) => {
        let result = JSON.parse(res);
        that.ggNum = result[0].action_id;
        that.mmNum = result[1].action_id;
        that.yrNum = result[2].action_id;

        let pop1 = document.getElementById("popup");
        let pop2 = document.getElementById("popup2");
        let pop3 = document.getElementById("popup3");

        if (result[0].action_id > 6) {
          pop3.classList.remove("hide");
          if (result[0].action_id == 13 || result[0].action_id >= 14) {
            pop3.style.background = "none";
          } else {
            pop3.style.background =
              "url('https://img.ohdat.io/peking_monsters/website/assets/icon/music/gg_pp.png') no-repeat";
            pop3.style.backgroundSize = "100% 100%";
          }
          that.nfcMove3();
        } else {
          pop3.classList.add("hide");
        }

        if (result[1].action_id > 6) {
          pop2.classList.remove("hide");
          if (result[1].action_id == 13 || result[1].action_id >= 14) {
            pop2.style.background = "none";
          } else {
            pop2.style.background =
              "url('https://img.ohdat.io/peking_monsters/website/assets/icon/music/mm_pp.png') no-repeat";
            pop2.style.backgroundSize = "100% 100%";
          }
          that.nfcMove2();
        } else {
          pop2.classList.add("hide");
        }
        if (result[2].action_id > 6) {
          pop1.classList.remove("hide");
          if (result[2].action_id == 13 || result[2].action_id >= 14) {
            pop1.style.background = "none";
          } else {
            pop1.style.background =
              "url('https://img.ohdat.io/peking_monsters/website/assets/icon/music/yr_pp.png') no-repeat";
            pop1.style.backgroundSize = "100% 100%";
          }
          that.nfcMove();
        } else {
          pop1.classList.add("hide");
        }
      });

      //音量
      ws.onChangeVolume((res) => {
        let result = JSON.parse(res);
        // console.log(result);
        if (result.Scene == 0) {
          //音乐
          that.volume = result.Vol / 100;
          rythm.player.audio.volume = that.volume;
          // console.log(rythm.player.audio.volume)
        } else if (result.Scene == 1) {
          //主持人
          // console.log(33333);
          that.audioVolume = result.Vol * 10;
          // console.log(that.audioVolume);
        }
      });
      //主持人发言
      // ws.onSpeaker((res) => {
      //   that.content = res;
      // });
      //活动开始0-1
      ws.onSwitchParty((res) => {
        let result = JSON.parse(res);
        console.log(111);
        console.log(result);
        if (result.seconds > 0) {
          // 音频
          that.fetch_rtc_token();
          that.timeOut();
          that.$refs.active.classList.add("opacity");
          setTimeout(() => {
            that.$refs.active.classList.remove("zIndex");
            that.$refs.active.classList.remove("closed");
            that.$refs.active.classList.remove("opacity");
            that.$refs.active.classList.add("opacityActive");
          }, 700);
        } else {
          that.onEndClick();
          that.$refs.active.classList.remove("opacityActive");
          that.$refs.active.classList.add("closed");
          that.$refs.active.classList.add("zIndex");
          that.musicStart = 0;
          localStorage.setItem("u_id", "");
          localStorage.setItem("imgurl1", "");
        }
      });

      //获取mygif
      ws.onPlayerAction((res) => {
        let result = JSON.parse(res);
        console.log(result);
        let numGif = Math.floor(Math.random() * 2 + 1);
        let op = document.getElementById("wrap");
        let odiv = op.querySelectorAll("div");
        for (let i = 0; i < odiv.length; i++) {
          if (odiv[i].getAttribute("id") == result.uid.toString()) {
            // odiv[i].querySelector('img').getAttribute('src')

            odiv[i].querySelector("img").src = that.roleAction(
              odiv[i].getAttribute("catalog"),
              that.videoBg,
              result.message
            );
            setTimeout(() => {
              odiv[i].querySelector("img").src = that.randomGenerateImage(
                that.videoBg,
                odiv[i].getAttribute("catalog")
              );
            }, 3000);
          }
        }
      });

      //移动
      ws.onPlayerMove((res) => {
        let that = this;
        let result = JSON.parse(res);
        // console.log(1111);
        // console.log(res);
        for (let i = 0; i < that.balls.length; i++) {
          if (that.balls[i].getAttribute("id") == result.uId.toString()) {
            that.keyCodeImg(i);
            let op = document.getElementById("wrap");
            let odiv = op.querySelectorAll("div");
            let realX = that.realPosition(result.x, "x")
            let realY = that.realPosition(result.y, "y")
            if (realX >= that.balls[i].x || realY >= that.balls[i].y){
              odiv[i].querySelector("img").style.transform = "scale(-1,1)";
            } else {
              odiv[i].querySelector("img").style.transform = "scale(1,1)";
            }
            that.moveToPosition(
              that.balls[i],
              realX,
              realY,
              1000
            );
          }
        }
      });

      //瓶盖雨
      ws.onCapsRain((res) => {
        console.log(this.u_id, this.videoBg);
        let that = this;
        let data = JSON.parse(res);
        console.log(data.account_lt);
        if (that.videoBg == 3) {
          that.capsThem3 = true;
          setTimeout(() => {
            that.capsThem3 = false;
          }, 2500);
        } else if (that.videoBg == 4) {
          that.capsThem4 = true;
          setTimeout(() => {
            that.capsThem4 = false;
          }, 2500);
        } else {
          that.capsThem5 = true;
          setTimeout(() => {
            that.capsThem5 = false;
          }, 2500);
        }
        for (let i = 0; i < data.account_lt.length; i++) {
          if (data.account_lt[i].uid.toString() == that.u_id) {
            that.bottleCap = data.account_lt[i].cap_num;
          }
        }
      });

      //刷礼物
      ws.onShowerGifts((res) => {
       
        let that = this;
        let data = JSON.parse(res);
         console.log(data);
        if (data.e_type == 1) {
          that.rocketgift(data.nickname);
        } else if (data.e_type == 2) {
          that.rainSars();
        } else {
          that.fireShow(data.e_type);
        }
        if(data.gift_rank != null){
          that.spand = 'spent'
          that.rankingName = data.gift_rank[0].name;
          that.rankingCaps = data.gift_rank[0].score;
        }
      });

      //榜一
      ws.onRankingList((res) => {
        console.log("11111===" + res);
      });
    },
    //控制左右
    controlDong() {
      let that = this;
      let op = document.getElementById("wrap");
      let odiv = op.querySelectorAll("div");
      window.onkeydown = function (e) {
        let leftGo = "";
        let topGo = "";
        if (that.moveStop) {
          // alert(that.videoBg)
          for (let i = 0; i < odiv.length; i++) {
            if (odiv[i].getAttribute("id") == that.u_id.toString()) {
              leftGo = Number(
                odiv[i].style.left.slice(0, odiv[i].style.left.length - 2)
              );
              topGo = Number(
                odiv[i].style.top.slice(0, odiv[i].style.top.length - 2)
              );
            }
          }

          // console.log(topGo);
          //键盘箭头操作
          for (let i = 0; i < that.balls.length; i++) {
            if (that.balls[i].getAttribute("id") == that.u_id.toString()) {
              if (e.keyCode === 37) {
                // console.log("左");
                leftGo = leftGo - 50
              } else if (e.keyCode === 38) {
                // console.log("上");
                topGo = topGo - 50
              } else if (e.keyCode === 39) {
                // console.log("右");
                leftGo = leftGo + 50
              } else if (e.keyCode === 40) {
                // console.log("下");
                topGo = topGo + 50
              }
            }
          }
          var boxinin = document.getElementById("wrap");
          if (leftGo <= 0) {
            leftGo = 0;
          }
          // console.log(boxinin.clientWidth);
          if (leftGo >= boxinin.clientWidth) {
            // leftGo =1000
            leftGo = boxinin.clientWidth;
          }
          if (topGo <= 0) {
            topGo = 0;
          }
          if (topGo >= boxinin.clientHeight) {
            topGo = boxinin.clientHeight;
          }

          // console.log("leftGo===="+leftGo)
          let message = JSON.stringify({
            uid: that.u_id,
            x: (leftGo * 2560) / document.documentElement.clientWidth,
            y: (topGo * 1440) / document.documentElement.clientHeight,
          });
          if (that.moveFlag) {
            that.moveFlag = false;
            ws.sendPlayerMove(that.u_id, message);
            setTimeout(() => {
              that.moveFlag = true;
            }, 500);
          }
        }
      };
      document.addEventListener("keyup", function (e) {
        /*let timers = 2000;
         setTimeout(() => {
          that.moveflag = true;
        }, 100);
        for (let i = 0; i < odiv.length; i++) {
          if (odiv[i].getAttribute("id") == that.u_id.toString()) {
            if (odiv[i].getAttribute("catalog") == 1) {
              //mm
              setTimeout(() => {
                odiv[i].querySelector(
                  "img"
                ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/mm/mm1.gif`;
              }, timers);
            } else if (odiv[i].getAttribute("catalog") == 2) {
              //gg
              setTimeout(() => {
                odiv[i].querySelector(
                  "img"
                ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/gg/gg1.gif`;
              }, timers);
            } else if (odiv[i].getAttribute("catalog") == 3) {
              //yr
              setTimeout(() => {
                odiv[i].querySelector(
                  "img"
                ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/yr/yr1.gif`;
              }, timers);
            } else if (odiv[i].getAttribute("catalog") == 4) {
              //mfers
              setTimeout(() => {
                odiv[i].querySelector(
                  "img"
                ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/mfers/mfers1.gif`;
              }, timers);
            } else if (odiv[i].getAttribute("catalog") == 5) {
              //doge
              setTimeout(() => {
                odiv[i].querySelector(
                  "img"
                ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/doge/doge1.gif`;
              }, timers);
            } else if (odiv[i].getAttribute("catalog") == 6) {
              //dudu
              setTimeout(() => {
                odiv[i].querySelector(
                  "img"
                ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/dudu/dudu1.gif`;
              }, timers);
            }
          }
        }*/
      });
    },
    keyCodeImg(i) {
      let that = this
      const timerRearrange = 1000
      that.balls[i].movingTime = that.balls[i].movingTime + timerRearrange
      if (that.balls[i].getAttribute("catalog") == 1) {
        //mm
        that.balls[i].querySelector(
            "img"
        ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them${that.videoBg}/mm.gif`;
        setTimeout(() => {
          that.balls[i].movingTime = that.balls[i].movingTime - timerRearrange
          if (that.balls[i].movingTime<=0){
            that.balls[i].querySelector(
                "img"
            ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/mm/mm1.gif`;
          }
        }, timerRearrange);
      } else if (that.balls[i].getAttribute("catalog") == 2) {
        //gg
        that.balls[i].querySelector(
            "img"
        ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them${that.videoBg}/gg.gif`;
        setTimeout(() => {
          that.balls[i].movingTime = that.balls[i].movingTime - timerRearrange
          if (that.balls[i].movingTime<=0){
            that.balls[i].querySelector(
                "img"
            ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/gg/gg1.gif`;
          }
        }, timerRearrange);
      } else if (that.balls[i].getAttribute("catalog") == 3) {
        //yr
        that.balls[i].querySelector(
            "img"
        ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them${that.videoBg}/yr.gif`;
        setTimeout(() => {
          that.balls[i].movingTime = that.balls[i].movingTime - timerRearrange
          if (that.balls[i].movingTime<=0){
            that.balls[i].querySelector(
                "img"
            ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/yr/yr1.gif`;
          }
        }, timerRearrange);
      } else if (that.balls[i].getAttribute("catalog") == 4) {
        //mfers
        that.balls[i].querySelector(
            "img"
        ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them${that.videoBg}/mfers.gif`;
        setTimeout(() => {
          that.balls[i].movingTime = that.balls[i].movingTime - timerRearrange
          if (that.balls[i].movingTime<=0){
            that.balls[i].querySelector(
                "img"
            ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/mfers/mfers1.gif`;
          }
        }, timerRearrange);
      } else if (that.balls[i].getAttribute("catalog") == 5) {
        //mfers
        that.balls[i].querySelector(
            "img"
        ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them${that.videoBg}/doge.gif`;
        setTimeout(() => {
          that.balls[i].movingTime = that.balls[i].movingTime - timerRearrange
          if (that.balls[i].movingTime<=0){
            that.balls[i].querySelector(
                "img"
            ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/doge/doge1.gif`;
          }
        }, timerRearrange);
      } else if (that.balls[i].getAttribute("catalog") == 6) {
        //mfers
        that.balls[i].querySelector(
            "img"
        ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/move/them${that.videoBg}/dudu.gif`;
        setTimeout(() => {
          that.balls[i].movingTime = that.balls[i].movingTime - timerRearrange
          if (that.balls[i].movingTime<=0){
            that.balls[i].querySelector(
                "img"
            ).src = `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${that.videoBg}/dudu/dudu1.gif`;
          }
        }, timerRearrange);
      }
    },
    //病毒雨
    rainmouseover(num) {
      this.messageTip = true;
      if (num == 2) {
        //雨
        this.iconTitle = "病毒雨";
        this.iconTitle_en = "Virus Rain";
        this.iconTip = "雨一直下！气氛非常融洽！";
        this.iconTip_en = "Let the virus RAIN! ";
        this.iconNum = "800";
        if (this.videoBg == 4) {
          this.tanImg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rain.png";
        } else {
          this.tanImg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rainhover.png";
        }
      } else if (num == 1) {
        this.iconTitle = "超级火箭";
        this.iconTitle_en = "Ruakkkket";
        this.iconTip = "飞天超火，点亮夜空！需要 ";
        this.iconTip_en = "Rocket? Rock it!! ";
        this.iconNum = "2000";
        if (this.videoBg == 4) {
          this.tanImg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rockt4.png";
        } else {
          this.tanImg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/music/rockt3.png";
        }
      } else {
        this.iconTitle = "冷烟火";
        this.iconTitle_en = "Firework";
        this.iconTip = "一起拉烟儿！需要 ";
        this.iconTip_en = "Back off! The chemicals are working... ";
        this.iconNum = "300";
        if (this.videoBg == 4) {
          this.tanImg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/music/fireicon.png";
        } else {
          this.tanImg =
            "https://img.ohdat.io/peking_monsters/website/assets/icon/music/fireicon3.png";
        }
      }
    },
    rainmouseleave() {
      this.messageTip = false;
    },
    handle1() {
      ws.sendPlayerAction(this.u_id, 1);
    },
    handle2() {
      ws.sendPlayerAction(this.u_id, 2);
    },
    handle3() {
      ws.sendPlayerAction(this.u_id, 3);
    },
    handleBz() {
      ws.sendPlayerAction(this.u_id, 4);
    },
    fn() {
      let fly1 = document.getElementById("fly1");
      let fly2 = document.getElementById("fly2");
      let fly3 = document.getElementById("fly3");
      setTimeout(() => {
        fly1.classList.add("flydong");
        if (fly1.getAttribute("class").includes("flydong")) {
          fly3.classList.remove("flydong");
        }
        setTimeout(() => {
          fly2.classList.add("flydong");
          fly1.classList.remove("flydong");
          setTimeout(() => {
            fly3.classList.add("flydong");
            fly2.classList.remove("flydong");
          }, this.flyNum);
        }, this.flyNum);
      }, this.flyNum);
    },
    randomUid() {
      let ids = [];
      let arr = this.randomArr;
      for (const item of arr) {
        ids.push(item.uid);
        if (ids.length >= 10) {
          return ids;
        }
      }
      return ids;
    },
    //角色动作
    roleAction(catalog, videoBg, message) {
      console.log(catalog, message);
      let role = "";
      if (catalog == 1) {
        if (message == 1) {
          role = "mm_hand";
        } else if (message == 2) {
          role = "mm_flag";
        } else if (message == 3) {
          role = "mm_jump";
        } else if (message == 4) {
          role = "mm_blast";
        }
      } else if (catalog == 2) {
        if (message == 1) {
          role = "gg_hand";
        } else if (message == 2) {
          role = "gg_flag";
        } else if (message == 3) {
          role = "gg_jump";
        } else if (message == 4) {
          role = "gg_blast";
        }
      } else if (catalog == 3) {
        if (message == 1) {
          role = "yr_hand";
        } else if (message == 2) {
          role = "yr_flag";
        } else if (message == 3) {
          role = "yr_jump";
        } else if (message == 4) {
          role = "yr_blast";
        }
      } else if (catalog == 4) {
        if (message == 1) {
          role = "mefers_jump";
        } else if (message == 2) {
          role = "mefers_jump";
        } else if (message == 3) {
          role = "mefers_jump";
        } else if (message == 4) {
          role = "mefers_blast";
        }
      } else if (catalog == 5) {
        if (message == 1) {
          role = "doge_jump";
        } else if (message == 2) {
          role = "doge_jump";
        } else if (message == 3) {
          role = "doge_jump";
        } else if (message == 4) {
          role = "doge_blast";
        }
      } else if (catalog == 6) {
        if (message == 1) {
          role = "dudu_jump";
        } else if (message == 2) {
          role = "dudu_jump";
        } else if (message == 3) {
          role = "dudu_jump";
        } else if (message == 4) {
          role = "dudu_blast";
        }
      }
      console.log(role);
      return `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/icon/them${videoBg}/${role}.gif`;
    },
    sendMes(divv, msg) {
      let str = msg;
      // console.log(1111)
      // console.log(str)
      if (escape(str).indexOf("%u") < 0) {
        if (str.length > 20) {
          str = str.substr(0, 20) + "...";
          divv.querySelector("p").innerHTML = str;
        } else {
          divv.querySelector("p").innerHTML = str;
        }
      } else {
        if (str.length > 10) {
          str = str.substr(0, 10) + "...";
          divv.querySelector("p").innerHTML = str;
        } else {
          divv.querySelector("p").innerHTML = str;
        }
      }

      divv.querySelector("p").style.display = "flex";

      let nowTime = new Date().getTime() / 1000;
      divv.querySelector("p").setAttribute("close-time", nowTime + 5);
      setTimeout(() => {
        let clostTime = divv.querySelector("p").getAttribute("close-time");
        console.log("clostTime", clostTime);
        let nowTime = new Date().getTime() / 1000;
        if (nowTime >= clostTime) {
          divv.querySelector("p").style.display = "none";
        }
      }, 5000);
    },

    //音频options
    async fetch_rtc_token() {
      let params = {
        uid: this.options.uid,
        role: 2,
      };
      let res = await fetch_rtc_token(params);
      this.options.appid = res.data.app_id;
      this.options.channel = res.data.channel_name;
      this.options.token = res.data.token;
      this.options.uid;
      this.joinAudio();
    },
    async joinAudio() {
      let rtc = {
        // For the local audio and video tracks.
        localAudioTrack: null,
        localVideoTrack: null,
      };
      //随机uid
      // this.options.uid = Math.floor(Math.random() * 100000);
      // Add an event listener to play remote tracks when remote user publishes.
      // client.on("user-published", this.handleUserPublished());
      client.on("user-published", async (user, mediaType) => {
        // 开始订阅远端用户。
        await client.subscribe(user, "audio");

        console.log("subscribe success");
        // 表示本次订阅的是音频。
        if (mediaType === "audio") {
          //  user 中获取远端音频轨道对象。
          user.audioTrack.setVolume(this.audioVolume);
          this.audioTrack.push(user);
          // console.log(77777);
          // console.log(this.audioVolume);
        }
      });
      client.on("token-privilege-will-expire", async function () {
        let token = await fetchToken(
          this.options.uid,
          this.options.channel,
          this.u_id
        );
        await client.renewToken(token);
      });

      // When token-privilege-did-expire occurs, fetches a new token from the server and call join to rejoin the channel.
      client.on("token-privilege-did-expire", async function () {
        console.log("Fetching the new Token");
        let token = await fetchToken(
          this.options.uid,
          this.options.channel,
          this.u_id
        );
        console.log("Rejoining the channel with new Token");
        await rtc.client.join(
          this.options.appid,
          this.options.channel,
          token,
          this.options.uid
        );
      });

      // Join a channel and create local tracks. Best practice is to use Promise.all and run them concurrently.
      await client.join(
        this.options.appid,
        this.options.channel,
        this.options.token,
        this.options.uid
      );

      console.log("publish success");
    },
    openseaLink() {
       window.open("https://opensea.io/collection/pekingmonsters");
    },
    twitter() {
      window.open("https://twitter.com/Peking_Monsters");
    },
    redirect() {
      window.open("https://discord.gg/pekingmonsters")
      // window.open("https://discord-verify.pekingmonsters.xyz/v2/redirect");
    },
    async initial() {
      this.randomArr = [];
      let data = await initial();
      // console.log(data.data);
      this.randomArr = data.data;
      this.createballs();
      this.controlDong();
    },

    //金字塔
    pyramid() {
      let that = this;
      for (let i = 0; i < that.balls.length; i++) {
        if (i < 100) {
          that.moveToPosition(
            that.balls[i],
            this.realPosition(that.pyramArr[i].x * 2, "x"),
            this.realPosition(that.pyramArr[i].y * 2, "y"),
            2000
          );
        } else {
          this.moveToPosition(
            this.balls[i],
            this.realPosition(this.randomNum(300, 600), "x"),
            this.realPosition(800, "y"),
            2000
          );
        }
      }
      setTimeout(() => {
        that.hongqi = false;
        that.resetAllBalls();
      }, 9000);
    },
    //love
    lovely() {
      for (let i = 0; i < this.balls.length; i++) {
        if (i < 83) {
          this.moveToPosition(
            this.balls[i],
            this.realPosition(this.loveArr[i].x * 2, "x"),
            this.realPosition(this.loveArr[i].y * 2, "y"),
            2000
          );
        } else {
          this.moveToPosition(
            this.balls[i],
            this.realPosition(this.randomNum(300, 600), "x"),
            this.realPosition(800, "y"),
            2000
          );
        }
      }
      setTimeout(() => {
        this.resetAllBalls();
      }, 9000);
    },
    //矩阵路线
    squareness() {
      let input = this.randomUid();
      let n = 0;
      for (let i = 0; i < this.balls.length; i++) {
        if (input.indexOf(this.balls[i].id) > -1) {
          this.moveToPosition(
            this.balls[i],
            this.realPosition(this.squarenessArr[n].x * 2, "x"),
            this.realPosition(this.squarenessArr[n].y * 2, "y"),
            1000
          );
          n++;
        }
      }
    },
    roadArrness() {
      let input = this.randomUid();
      let n = 0;
      for (let i = 0; i < this.balls.length; i++) {
        if (input.indexOf(this.balls[i].id) > -1) {
          this.moveToPosition(
            this.balls[i],
            this.realPosition(this.roadArr[n].x * 2, "x"),
            this.realPosition(this.roadArr[n].y * 2, "y"),
            1000
          );
          n++;
        }
      }
    },
    //方形
    go() {
      let that = this;
      let repeatTimer = setInterval(() => {
        for (let i = 0; i < that.squarenessArr.length; i++) {
          if (
            that.squarenessArr[i].x < 900 - 180 * that.squarenessArr[i].num &&
            that.squarenessArr[i].y >= 200 - 40 * that.squarenessArr[i].num
          ) {
            that.squarenessArr[i].x = that.squarenessArr[i].x + 24;
            that.move(i, that.squarenessArr, 0);
          } else if (
            that.squarenessArr[i].x >= 900 - 180 * that.squarenessArr[i].num &&
            that.squarenessArr[i].y > 50 + 40 * that.squarenessArr[i].num
          ) {
            that.squarenessArr[i].y = that.squarenessArr[i].y - 24;
            that.move(i, that.squarenessArr, 0);
          } else if (
            that.squarenessArr[i].x > 50 + 180 * that.squarenessArr[i].num &&
            that.squarenessArr[i].y <= 50 + 40 * that.squarenessArr[i].num
          ) {
            that.squarenessArr[i].x = that.squarenessArr[i].x - 24;
            that.move(i, that.squarenessArr, 0);
          } else if (
            that.squarenessArr[i].x <= 50 + 180 * that.squarenessArr[i].num &&
            that.squarenessArr[i].y <= 160 - 40 * that.squarenessArr[i].num
          ) {
            that.squarenessArr[i].y = that.squarenessArr[i].y + 24;
            that.move(i, that.squarenessArr, 0);
            if (
              that.squarenessArr[i].y >
              160 - 40 * that.squarenessArr[i].num
            ) {
              if (that.squarenessArr[9].num == 2) {
                that.squarenessArr = JSON.parse(
                  JSON.stringify(that.squarenessArrCO)
                );
                that.resetAllBalls();
                return;
              } else {
                that.squarenessArr[i].num++;
              }
            }
          }
          that.Crash(that.balls[i], 0, 10);
        }
      }, that.sqTimer);

      that.repeat.push(repeatTimer);
    },

    //弯路线
    road() {
      let that = this;
      const start = 0;
      // console.log(that.sfsfrsv);
      let timerRoad = setInterval(() => {
        for (let i = 0; i < that.roadArr.length; i++) {
          if (that.roadArr[i].x < 300) {
            that.roadArr[i].x = that.roadArr[i].x + 24;
            that.move(i, that.roadArr, start);
          } else if (that.roadArr[i].x < 600 && that.roadArr[i].y > 50) {
            that.roadArr[i].y = that.roadArr[i].y - 24;
            that.move(i, that.roadArr, start);
          } else if (that.roadArr[i].x < 600) {
            that.roadArr[i].x = that.roadArr[i].x + 24;
            that.move(i, that.roadArr, start);
          } else if (that.roadArr[i].x < 900 && that.roadArr[i].y < 200) {
            that.roadArr[i].y = that.roadArr[i].y + 24;
            that.move(i, that.roadArr, start);
          } else if (that.roadArr[i].x < 900) {
            that.roadArr[i].x = that.roadArr[i].x + 24;
            that.move(i, that.roadArr, start);
          } else if (that.roadArr[i].x < 1000 && that.roadArr[i].y > 50) {
            that.roadArr[i].y = that.roadArr[i].y - 24;
            that.move(i, that.roadArr, start);
          } else {
            that.roadArr = JSON.parse(JSON.stringify(that.sfsfrsv));
            that.resetAllBalls();
            return;
          }
          that.Crash(that.balls[i + start], start, start + 10);
        }
      }, that.roadtimes);

      that.repeat.push(timerRoad);
    },
    //移动走动画封装
    move(i, arrList, start) {
      this.moveToPosition(
        this.balls[i + start],
        this.realPosition(arrList[i].x * 2, "x"),
        this.realPosition(arrList[i].y * 2, "y"),
        this.roadtimes
      );
    },

    //从当前位置瞎撞
    randomMoveAll() {
      for (let i = 0; i < this.balls.length; i++) {
        //将所有的小球传到函数中,来实现对小球的移动
        this.randomMoveBalls(this.balls[i]);
      }
      setTimeout(() => {
        this.resetAllBalls();
      }, 10000);
    },
    //某球分散到两边
    singleSeparateToSide(ballId, time) {
      //将所有的小球传到函数中,来实现对小球的移动
      if (this.balls[ballId].x > this.realPosition(960, "x")) {
        this.moveToPosition(
          this.balls[ballId],
          this.balls[ballId].x +
            (this.realPosition(1920, "x") - this.balls[ballId].x) / 2,
          this.balls[ballId].y,
          time
        );
      } else {
        this.moveToPosition(
          this.balls[ballId],
          this.balls[ballId].x - this.balls[ballId].x / 2,
          this.balls[ballId].y,
          time
        );
      }
    },
    //某球移动至中央
    singleMoveToCenter(ballId, time) {
      //将所有的小球传到函数中,来实现对小球的移动
      if (this.balls[ballId].x > this.realPosition(960, "x")) {
        this.moveToPosition(
          this.balls[ballId],
          this.balls[ballId].x - 480,
          this.balls[ballId].y,
          time
        );
      } else {
        this.moveToPosition(
          this.balls[ballId],
          this.balls[ballId].x + 480,
          this.balls[ballId].y,
          time
        );
      }
    },
    //站队后瞎撞
    randomMoveAfterSeparate() {
      // this.punch();
      const that = this;
      for (let i = 0; i < that.balls.length; i++) {
        that.singleSeparateToSide(i, 2000);
      }
      setTimeout(function () {
        for (let i = 0; i < that.balls.length; i++) {
          that.singleMoveToCenter(i, 2000);
        }
      }, 2000);
      setTimeout(that.randomMoveAll, 4000);
      setTimeout(function () {
        that.resetAllBalls();
      }, 10000);
    },
    //疯狂对撞
    punch() {
      const that = this;
      let left = [];
      let right = [];
      for (let i = 0; i < that.balls.length; i++) {
        that.singleSeparateToSide(i, 2000);
        if (that.balls[i].x > that.realPosition(960, "x")) {
          right.push(i);
        } else {
          left.push(i);
        }
      }

      let interval = 500;
      let baseInterval = 1000;
      let count = 0;
      setTimeout(function () {
        for (let i = 0; i < left.length; i++) {
          that.moveToPosition(
            that.balls[left[i]],
            that.balls[left[i]].x + 400,
            that.balls[left[i]].y,
            baseInterval
          );
        }
        for (let i = 0; i < right.length; i++) {
          that.moveToPosition(
            that.balls[right[i]],
            that.balls[right[i]].x - 400,
            that.balls[right[i]].y,
            baseInterval
          );
        }
      }, baseInterval);
      setTimeout(function () {
        for (let i = 0; i < left.length; i++) {
          that.moveToPosition(
            that.balls[left[i]],
            that.balls[left[i]].x - 220,
            that.balls[left[i]].y,
            interval
          );
        }
        for (let i = 0; i < right.length; i++) {
          that.moveToPosition(
            that.balls[right[i]],
            that.balls[right[i]].x + 220,
            that.balls[right[i]].y,
            interval
          );
        }
      }, count * interval + baseInterval * 2);
      count++;
      setTimeout(function () {
        for (let i = 0; i < left.length; i++) {
          that.moveToPosition(
            that.balls[left[i]],
            that.balls[left[i]].x + 240,
            that.balls[left[i]].y,
            interval
          );
        }
        for (let i = 0; i < right.length; i++) {
          that.moveToPosition(
            that.balls[right[i]],
            that.balls[right[i]].x - 240,
            that.balls[right[i]].y,
            interval
          );
        }
      }, count * interval + baseInterval * 2);
      count++;
      setTimeout(function () {
        for (let i = 0; i < left.length; i++) {
          that.moveToPosition(
            that.balls[left[i]],
            that.balls[left[i]].x - 220,
            that.balls[left[i]].y,
            interval
          );
        }
        for (let i = 0; i < right.length; i++) {
          that.moveToPosition(
            that.balls[right[i]],
            that.balls[right[i]].x + 220,
            that.balls[right[i]].y,
            interval
          );
        }
      }, count * interval + baseInterval * 2);
      count++;
      setTimeout(function () {
        for (let i = 0; i < left.length; i++) {
          that.moveToPosition(
            that.balls[left[i]],
            that.balls[left[i]].x + 240,
            that.balls[left[i]].y,
            interval
          );
        }
        for (let i = 0; i < right.length; i++) {
          that.moveToPosition(
            that.balls[right[i]],
            that.balls[right[i]].x - 240,
            that.balls[right[i]].y,
            interval
          );
        }
      }, count * interval + baseInterval * 2);
      count++;
      setTimeout(function () {
        for (let i = 0; i < left.length; i++) {
          that.moveToPosition(
            that.balls[left[i]],
            that.balls[left[i]].x - 220,
            that.balls[left[i]].y,
            interval
          );
        }
        for (let i = 0; i < right.length; i++) {
          that.moveToPosition(
            that.balls[right[i]],
            that.balls[right[i]].x + 220,
            that.balls[right[i]].y,
            interval
          );
        }
      }, count * interval + baseInterval * 2);
      count++;
      setTimeout(function () {
        for (let i = 0; i < left.length; i++) {
          that.moveToPosition(
            that.balls[left[i]],
            that.balls[left[i]].x + 240,
            that.balls[left[i]].y,
            interval
          );
        }
        for (let i = 0; i < right.length; i++) {
          that.moveToPosition(
            that.balls[right[i]],
            that.balls[right[i]].x - 240,
            that.balls[right[i]].y,
            interval
          );
        }
      }, count * interval + baseInterval * 2);
      count++;

      setTimeout(function () {
        that.resetAllBalls();
      }, count * interval + baseInterval * 2);
    },
    //ws 推送rest
    wsRest() {
      this.resetAllBalls();
    },
    //重置
    resetAllBalls() {
      if (this.repeat != "") {
        for (let i = 0; i < this.repeat.length; i++) {
          clearInterval(this.repeat[i]);
        }
      }

      for (let i = 0; i < this.balls.length; i++) {
        this.singleReturnToInitial(i, 2000);
      }
      setTimeout(function () {
        for (let i = 0; i < this.repeat.length; i++) {
          clearInterval(this.repeat[i]);
        }
      }, 2500);
      setTimeout(() => {
        this.playing = false;
        this.moveStop = true;
      }, 3000);
    },
    //某球复原至初始
    singleReturnToInitial(ballId, time) {
      //将所有的小球传到函数中,来实现对小球的移动
      this.moveToPosition(
        this.balls[ballId],
        this.realPosition(this.randomArr[ballId].x * 0.95, "x"),
        this.realPosition(this.randomArr[ballId].y * 1.2, "y"),
        time
      );
    },
    realPosition(position, axie) {
      if (axie == "x") {
        return (position * document.documentElement.clientWidth) / 2560;
      } else {
        return (position * document.documentElement.clientHeight) / 1440;
      }
    },
    //是否加入
    checkJoin() {
      //判断是否登录过蹦迪场景
      if (localStorage.getItem("u_id")) {
        this.musicStart = 1;
        this.u_id = localStorage.getItem("u_id");
        this.imgurl1 = localStorage.getItem("imgurl1");
        this.balance_of();
      } else {
        this.musicStart = 0;
      }
    },
    //用户余额
    async balance_of() {
      let data = await balance_of();
      console.log(data);
      //瓶盖数 party币
      this.bottleCap = data.data.caps;
      this.party_token = data.data.party_token;
    },
    //加入
    async join() {
      if (!this.start) {
        this.onStartClick();
      }
      let that = this;
      console.log(that.contractAddress);
      console.log(that.tokenId);
      console.log(that.inputAddress);
      console.log(that.currentAccounttext);
      const params = {
        contract_address: that.contractAddress,
        token_id: that.tokenId.toString(),
        url: that.imgurl,
        wallet_id: that.currentAccounttext,
        nickname: that.inputAddress,
        // this.walletAddress
      };
      let data = await login(params);

      if (data.code == 200) {
        localStorage.setItem("nickname", data.data.info.nickname);
        if (data.data.info.nickname == "") {
          that.nickname = that.walletAddress;
        } else {
          that.nickname = data.data.info.nickname;
        }
        setTimeout(() => {
          that.haveTicket = true;
        }, 500);
        let videoDOM = document.getElementById("video");
        setTimeout(() => {
          that.$refs.bgmv.classList.add("hide");
          that.$refs.up.classList.add("bg");
          that.$refs.top.classList.add("opacity");
          that.$refs.topbox.classList.add("opacity");
          that.$refs.cenbox.classList.add("opacity");
          setTimeout(() => {
            that.$refs.leftImg.classList.add("addleft");
            that.$refs.rightImg.classList.add("addright");
          }, 1000);
          videoDOM.play();
        }, 1000);
        setTimeout(() => {
          that.musicStart = 1;
          that.$refs.bgmv.classList.remove("hide");
          that.$refs.up.classList.remove("bg");
          that.$refs.top.classList.remove("opacity");
          that.$refs.topbox.classList.remove("opacity");
          that.$refs.cenbox.classList.remove("opacity");
          that.$refs.leftImg.classList.remove("addleft");
          that.$refs.rightImg.classList.remove("addright");
        }, 4500);

        that.randomArr = [];
        that.balls = [];
        let wrapp = document.getElementById("wrap");
        wrapp.innerHTML = "";
        that.imgurl1 = data.data.info.url;
        that.u_id = data.data.info.uId;
        localStorage.setItem("u_id", data.data.info.uId);
        localStorage.setItem("imgurl1", data.data.info.url);
        that.walletAddress =
          that.currentAccounttext.substr(0, 4) +
          "***" +
          that.currentAccounttext.substr(-4);
        that.initial();
        that.balance_of();
        that.controlDong();
      }
    },
    //返回0
    returnBack() {
      this.hongqi = false;
      // if (this.$refs.peopleList.scrollTop > 0) {
      //   this.$refs.peopleList.scrollTop = 0;
      // }
      this.selTabIndex = 0;
      localStorage.setItem("u_id", "");
      localStorage.setItem("imgurl1", "");
      this.musicStart = 0;
      this.page = 1;
      this.pageMefers = 1;
      this.pagedudu = 1;
      this.pagedoge = 1;
      this.arr = [];
      this.arrMefers = [];
      this.arrdoge = [];
      this.arrdudu = [];
      this.headarImg = [];
      this.headarImgMefers = [];
      this.headarImgdoge = [];
      this.headarImgdudu = [];
      this.haveTicket = false;
      this.opensea();
    },
    handlemessage() {
      if (!this.len) {
        this.$refs.message.classList.add("messageTrans");
        this.len = true;
      } else {
        this.$refs.message.classList.add("messageBack");
        setTimeout(() => {
          this.$refs.message.classList.remove("messageBack");
          this.$refs.message.classList.remove("messageTrans");
        }, 1000);
        this.len = false;
      }
    },
    //发送留言
    async send() {
      if (this.val == "") {
        if (this.decipherment) {
          Message({
            message: "嗯？空白信息？",
            type: "error",
          });
        } else {
          Message({
            message: " Message required",
            type: "error",
          });
        }
      } else {
        const params = {
          message: this.val,
        };
        let data = await chat(params);
        // console.log(data)
        if (data.code == 200) {
          this.val = "";
        }

        let odiv = document.getElementById("scrollbox");
        odiv.scrollTop = odiv.scrollHeight;
      }
    },
    getMessage(res) {
      let odiv = document.getElementById("scrollbox");
      this.boxArr.push(res);
      odiv.scrollTop = odiv.scrollTop + 100;
      console.log(odiv.scrollTop);
      setTimeout(() => {
        let odiv2 = document.getElementById("scrollbox");
        odiv2.scrollTop = odiv2.scrollHeight;
      }, 1000);

      // console.log(this.boxArr);
    },
    stopstrans() {
      //防止报错
    },

    async checkLogIn() {
      let currentAccount = await getCurrentAccount();
      this.currentAccounttext = currentAccount;
      if (currentAccount) {
        if (localStorage.getItem("wallet_address") != currentAccount) {
          localStorage.setItem("wallet_address", currentAccount);
        }
        this.loginWallet = true;
        this.walletAddress =
          currentAccount.substr(0, 4) + "***" + currentAccount.substr(-4);
        this.inputAddress = currentAccount.substr(0, 8);
        this.opensea();

        if (localStorage.getItem("nickname") == "") {
          this.nickname = this.walletAddress;
        } else {
          this.nickname = localStorage.getItem("nickname");
        }
      }
    },
    async connectWallet() {
      if (!this.start) {
        this.onStartClick();
      }

      let currentAccount = await getCurrentAccount();
      if (currentAccount == null) {
        await connectWallet();
      }
      this.loginWallet = true;
    },
    // handleScroll(e) {
    //   console.log(0);
    //   // 获取滚动的内容实际高度（包括超出可视窗的溢出部分）
    //   let scrollHeight = Math.floor(e.target.scrollHeight);
    //   // console.log("scrollHeight===" + scrollHeight);
    //   //获取滚动条滚动的距离
    //   let scrollTop = Math.floor(e.target.scrollTop);
    //   //获取窗口可视范围高度
    //   let clientHeight = Math.floor(e.target.clientHeight + 1);
    //   //  可视窗口高度加上滚动的距离大于或者等于的总内容高度的时候触发
    //   // console.log("clientHeight===" + clientHeight);
    //   // console.log("scrollTop===" + scrollTop);
    //   let that = this;
    //   if (clientHeight + scrollTop >= scrollHeight) {
    //     setTimeout(() => {
    //       that.loading = false;
    //       if (that.flag) {
    //         that.flag = false;
    //         that.opensea();
    //       }
    //     }, 500);
    //   }
    // },
    //获取头像
    async opensea() {
      // this.showNft = false

      const params = {
        limit: 1000,
        page: this.page,
        item_type: "peking_monsters",
      };
      let res = await opensea(params);
      // console.log(res);

      if (res.data.list == null) {
        this.showNft = true;
      } else {
        this.showNft = false;
        this.loading = true;
        
        

        if (res.data.list == null) {
          // this.loading = false;
        } else {
          this.flag = true;
          // this.page++;
          this.arr = res.data.list;
          // this.arr = this.arr.concat(this.headarImg);
          this.imgurl = res.data.list[0].target_info.cover.thumbnail_url;
          this.tokenId = res.data.list[0].token_id;
          this.contractAddress = res.data.list[0].token_address;
        }
      }
    },

    //选择头像
    selImg(contract_address, token_id, url, wallet_id, index) {
      // this.selected=!this.selected;
      this.imgurl = url;
      this.tokenId = token_id;
      this.currentAccounttext = wallet_id;
      this.contractAddress = contract_address;
      this.oimgIndex = index;
    },
    //选择头像
    selImgMefrs(contract_address, token_id, url, wallet_id, index) {
      // this.selected=!this.selected;
      this.imgurl = url;
      this.tokenId = token_id;
      this.currentAccounttext = wallet_id;
      this.contractAddress = contract_address;
      this.mefersSelIndex = index;
    },
    //选择头像
    selImgdoge(contract_address, token_id, url, wallet_id, index) {
      // this.selected=!this.selected;
      this.imgurl = url;
      this.tokenId = token_id;
      this.currentAccounttext = wallet_id;
      this.contractAddress = contract_address;
      this.dogeSelIndex = index;
    },
    //选择头像
    selImgdudu(contract_address, token_id, url, wallet_id, index) {
      // this.selected=!this.selected;
      this.imgurl = url;
      this.tokenId = token_id;
      this.currentAccounttext = wallet_id;
      this.contractAddress = contract_address;
      this.duduSelIndex = index;
    },
    //mefers头像
    async mefersNft() {
      const params = {
        contract: "0x79fcdef22feed20eddacbb2587640e45491b757f",
        owner: this.currentAccounttext,
        // owner: "0xc37648C0Dd2A79EF5932B62718D55C6bCBE13558",
        limit: 20,
        page: this.pageMefers,
      };
      let data = await listNft(params);
      if (data.data.list == null && this.pageMefers == 1) {
        this.showNft = true;
      } else {
        this.showNft = false;
        this.loadingMefers = true;
        this.flagMefers = true;
        this.pageMefers++;
        if (data.data.list == null) {
        } else {
          this.headarImgMefers = data.data.list;
          this.arrMefers = this.arrMefers.concat(this.headarImgMefers);
          this.imgurl = data.data.list[0].img;
          this.tokenId = data.data.list[0].token_id;
          this.contractAddress = data.data.list[0].contract;
        }
      }
    },
    handleScroll1(e) {
      console.log(1);
      // 获取滚动的内容实际高度（包括超出可视窗的溢出部分）
      let scrollHeight = Math.floor(e.target.scrollHeight);
      // console.log("scrollHeight===" + scrollHeight);
      //获取滚动条滚动的距离
      let scrollTop = Math.floor(e.target.scrollTop);
      //获取窗口可视范围高度
      let clientHeight = Math.floor(e.target.clientHeight + 1);
      //  可视窗口高度加上滚动的距离大于或者等于的总内容高度的时候触发
      // console.log("clientHeight===" + clientHeight);
      // console.log("scrollTop===" + scrollTop);
      let that = this;
      if (clientHeight + scrollTop >= scrollHeight) {
        setTimeout(() => {
          that.loadingMefers = false;
          if (that.flagMefers) {
            that.flagMefers = false;
            that.mefersNft();
          }
        }, 500);
      }
    },
    //doge头像
    async dogeNft() {
      const params = {
        contract: "0x9f648eef45b73e1a32ce7b624a44266226a85d88",
        owner: this.currentAccounttext,
        // owner: "0x066E61BBeC52684605eFAd92Af2B8621d4DaF106",
        limit: 20,
        page: this.pagedoge,
      };
      let data = await listNft(params);

      if (data.data.list == null && this.pagedoge == 1) {
        this.showNft = true;
      } else {
        this.loadingdoge = true;
        this.flagdoge = true;
        this.showNft = false;
        this.pagedoge++;
        if (data.data.list == null) {
        } else {
          this.headarImgdoge = data.data.list;
          this.arrdoge = this.arrdoge.concat(this.headarImgdoge);
          this.imgurl = data.data.list[0].img;
          this.tokenId = data.data.list[0].token_id;
          this.contractAddress = data.data.list[0].contract;
        }
      }
    },
    handleScroll2(e) {
      // 获取滚动的内容实际高度（包括超出可视窗的溢出部分）
      let scrollHeight = Math.floor(e.target.scrollHeight);
      console.log("scrollHeight===" + scrollHeight);
      //获取滚动条滚动的距离
      let scrollTop = Math.floor(e.target.scrollTop);
      //获取窗口可视范围高度
      let clientHeight = Math.floor(e.target.clientHeight + 1);
      //  可视窗口高度加上滚动的距离大于或者等于的总内容高度的时候触发
      console.log("clientHeight===" + clientHeight);
      console.log("scrollTop===" + scrollTop);
      let that = this;
      if (clientHeight + scrollTop >= scrollHeight) {
        setTimeout(() => {
          that.loadingdoge = false;
          if (that.flagdoge) {
            that.flagdoge = false;
            that.dogeNft();
          }
        }, 500);
      }
    },
    async duduNft() {
      const params = {
        contract: "0x365d87a8d31c656ed1479c0f54f19e3be9f19537",
        owner: this.currentAccounttext,
        // owner: "0xc37648C0Dd2A79EF5932B62718D55C6bCBE13558",
        limit: 20,
        page: this.pagedudu,
      };
      let data = await listNft(params);
      if (data.data.list == null && this.pagedudu == 1) {
        this.showNft = true;
      } else {
        this.showNft = false;
        this.loadingdudu = true;
        this.flagdudu = true;
        this.pagedudu++;
        if (data.data.list == null) {
        } else {
          this.headarImgdudu = data.data.list;
          this.arrdudu = this.arrdudu.concat(this.headarImgdudu);
          this.imgurl = data.data.list[0].img;
          this.tokenId = data.data.list[0].token_id;
          this.contractAddress = data.data.list[0].contract;
          console.log(this.arrdudu);
        }
      }
    },
    handleScroll3(e) {
      console.log(3);
      // 获取滚动的内容实际高度（包括超出可视窗的溢出部分）
      let scrollHeight = Math.floor(e.target.scrollHeight);
      // console.log("scrollHeight===" + scrollHeight);
      //获取滚动条滚动的距离
      let scrollTop = Math.floor(e.target.scrollTop);
      //获取窗口可视范围高度
      let clientHeight = Math.floor(e.target.clientHeight + 1);
      //  可视窗口高度加上滚动的距离大于或者等于的总内容高度的时候触发
      // console.log("clientHeight===" + clientHeight);
      // console.log("scrollTop===" + scrollTop);
      let that = this;
      if (clientHeight + scrollTop >= scrollHeight) {
        setTimeout(() => {
          that.loadingdudu = false;
          if (that.flagdudu) {
            that.flagdudu = false;
            that.duduNft();
          }
        }, 500);
      }
    },

    //音乐开始
    onStartClick() {
      if (!this.musicOn) {
        rythm.setMusic(this.musicUrl);
        rythm.start();
        const time = parseInt(new Date().getTime() / 1000);
        if (time - this.start_time > 0) {
          rythm.player.audio.currentTime = time - this.start_time;
        }
        // console.log(rythm.player.audio.currentTime)
      }
      this.arrowTip = true;
      this.musicOn = true;
      this.start = true;
      // alert(this.musicUrl)
      rythm.player.audio.volume = this.volume;
      // rythm.player.audio.volume = this.volume;
      // console.log(rythm.player.audio.volume);
      // rythm.player.audio.loop = true;
      this.audioTrack.map((item) => {
        item.audioTrack.play();
      });
      rythm.player.audio.addEventListener(
        "ended",
        function () {
          // alert('end')
          //结束
          rythm.setMusic("");
        },
        false
      );
    },
    onEndClick() {
      this.start = false;
      this.arrowTip = false;
      rythm.player.audio.volume = 0;
      this.audioTrack.map((item) => {
        item.audioTrack.stop();
      });
    },

    createdStart() {
      let max = 10;
      let min = 1;
      this.randomArr.forEach((item) => {
        Object.assign(item, {
          num: Math.floor(Math.random() * (max - min) + min),
        });
      });
    },
    musicDance() {
      rythm.addRythm("radius1", "twist", 0, 100, {
        reverse: true,
        min: -6,
        max: 6,
      });
      rythm.addRythm("radius2", "jump", 0, 100, {
        min: 0,
        max: 15,
      });
      rythm.addRythm("radius3", "shake", 0, 100, {
        direction: "left",
        min: -5,
        max: 5,
      });
      rythm.addRythm("radius4", "swing", 0, 200, {
        direction: "left",
        min: 0,
        max: 0.1,
      });
      rythm.addRythm("radius10", "twist", 0, 100, {
        reverse: true,
        min: -5,
        max: 5,
      });
      rythm.addRythm("radius6", "pulse", 0, 100, {
        min: 1,
        max: 1.2,
      });
      rythm.addRythm("radius7", "pulse", 0, 100, {
        min: 1,
        max: 1.5,
      });
      rythm.addRythm("radius8", "jump", 0, 100, {
        min: 0,
        max: 15,
      });
      rythm.addRythm("radius9", "shake", 0, 100, {
        direction: "left",
        min: -5,
        max: 5,
      });
      rythm.addRythm("radius10", "twist", 0, 100, {
        reverse: true,
        min: -5,
        max: 5,
      });
    },
    randomNum(m, n) {
      return Math.floor(Math.random() * (n - m + 1) + m);
    },
    //play3接口
    async createballs() {
      let wrapp = document.getElementById("wrap");
      wrapp.innerHTML = "";
      this.balls = [];
      let max = 10;
      let min = 1;
      //定义数组存储所有的小球
      for (let i = 0; i < this.randomArr.length; i++) {
        let ball = document.createElement("div");
        let num = Math.floor(Math.random() * (max - min) + min);
        ball.classList.add("radius" + num);
        //随机小球起始的X坐标和小球的Y坐标
        ball.x = this.realPosition(this.randomArr[i].x, "x");
        ball.y = this.realPosition(this.randomArr[i].y, "y");
        ball.id = this.randomArr[i]["uid"];
        ball.setAttribute("catalog", this.randomArr[i]["catalog"]);
        //随机小球的移动速度

        ball.speed = this.randomNum(2, 5);
        ball.movingTime = 0;

        //随机小球移动的方向

        if (Math.random() - 0.5 > 0) {
          ball.xflag = true;
        } else {
          ball.xflag = false;
        }

        if (Math.random() - 0.5 > 0) {
          ball.yflag = true;
        } else {
          ball.yflag = false;
        }

        //随机小球的背景颜色
        let srcImg = this.randomGenerateImage(
          this.videoBg,
          this.randomArr[i].catalog
        );

        // ball.style.backgroundRepeat = "no-repeat";
        // ball.style.backgroundSize = "100% 100%";
        let width = this.randomArr[i].size * 4 + "px";
        // ball.style.height = this.randomArr[i].size*4 + "px";
        ball.style.position = "absolute";
        ball.style.borderRadius = "5px";
        ball.style.color = "rgba(255,255,255,0.3)";
        ball.style.fontSize = "0.75rem";
        ball.style.transform = "none";
        ball.size = this.randomArr[i].size;
        let top = ball.size + 20 + "px";
        let top2 = ball.size - 30 + "px";
        let left = ball.size - 10 + "px";

        ball.innerHTML =
          '<img src="' +
          srcImg +
          '" style="width:' +
          width +
          ";z-index:" +
          ball.size +
          ' ;position: relative;"/><p style="width:100px;position: absolute;height:40px;top:' +
          top2 +
          ";left:" +
          left +
          ';color:#862D15;background:url(https://img.ohdat.io/peking_monsters/website/assets/icon/music/qp2.png) no-repeat;background-size:100% 100%;font-size:0.75rem;z-index:9999;display:none;align-items: center;justify-content:center;padding:5px;word-break: break-all;" class="engreg"></p><p class="engM" style="width:100%;z-index:999;position:absolute;top:' +
          top +
          ';left:0;text-align:center;">' +
          this.randomArr[i].nickname +
          "</p>";
        ball.style.top = ball.y + "px";
        ball.style.left = ball.x + "px";
        // ball.style.zIndex = ball.size;
        if (ball.size >= 70) {
          ball.style.color = "#FF00F5";
        }
        if (this.randomArr[i].uid == localStorage.getItem("u_id")) {
          // ball.style.zIndex = "999";
          ball.style.color = "#00FF66";
        }
        // 将小球插入当wrapp中

        wrapp.appendChild(ball);

        //将所有的小球存储到数组中

        this.balls.push(ball);
        // console.log(this.balls)
      }
    },

    randomGenerateImage(theme, catalog) {
      // console.log(theme);
      let numGif = 1;
      if (theme < 3) {
        numGif = Math.floor(Math.random() * 2 + 1);
      }
      let role = "";
      if (catalog == 1) {
        role = "mm";
      } else if (catalog == 2) {
        role = "gg";
      } else if (catalog == 3) {
        role = "yr";
      } else if (catalog == 4) {
        role = "mfers";
      } else if (catalog == 5) {
        role = "doge";
      } else if (catalog == 6) {
        role = "dudu";
      }
      return `https://img.ohdat.io/peking_monsters/website/assets/icon/stage/init/them${theme}/${role}/${role}${numGif}.gif`;
    },

    randomMoveBalls(ballObj) {
      let that = this;
      let intervalTime = 30;
      let tempRepeat = setInterval(function () {
        // ballObj.style.top = ballObj.y + "px";
        //
        // ballObj.style.left = ballObj.x + "px";

        //判断小球的标志量，对小球作出相应操作

        let wrapp = document.getElementById("wrap");
        if (ballObj.yflag) {
          //小球向下移动
          that.moveToPosition(
            ballObj,
            ballObj.x,
            ballObj.y + ballObj.speed,
            intervalTime
          );
          if (ballObj.y >= wrapp.offsetHeight / 2) {
            ballObj.yflag = false;
          }
        } else {
          //小球向上移动
          that.moveToPosition(
            ballObj,
            ballObj.x,
            ballObj.y - ballObj.speed,
            intervalTime
          );
          if (ballObj.y <= 0) {
            ballObj.yflag = true;
          }
        }

        if (ballObj.xflag) {
          //小球向右移动
          that.moveToPosition(
            ballObj,
            ballObj.x + ballObj.speed,
            ballObj.y,
            intervalTime
          );
          if (ballObj.x >= wrapp.offsetWidth) {
            console.log(ballObj.x);
            ballObj.xflag = false;
          }
        } else {
          //小球向左移动
          that.moveToPosition(
            ballObj,
            ballObj.x - ballObj.speed,
            ballObj.y,
            intervalTime
          );
          if (ballObj.x <= 0) {
            ballObj.xflag = true;
          }
        }

        that.randomCrash(ballObj);
      }, intervalTime);
      that.repeat.push(tempRepeat);
    },

    moveToPosition(ballObj, x, y, time) {
      let intervalTime = 50;
      let times = time / intervalTime;
      let speedX = (x - ballObj.x) / times;
      let speedY = (y - ballObj.y) / times;
      let count = 0;
      let tempRepeat = setInterval(function () {
        // console.log(count)
        if (count >= times) {
          clearInterval(tempRepeat);
        }
        ballObj.x += speedX;
        ballObj.y += speedY;
        ballObj.style.top = ballObj.y + "px";
        ballObj.style.left = ballObj.x + "px";
        count++;
      }, intervalTime);
      this.repeat.push(tempRepeat);
    },
    randomCrash(ballObj) {
      let x1 = "";
      let y1 = "";
      let x2 = "";
      let y2 = "";
      //通过传过来的小球对象来获取小球的X坐标和Y坐标

      x1 = ballObj.x;

      y1 = ballObj.y;

      for (let i = 0; i < this.balls.length; i++) {
        //确保不和自己对比

        if (ballObj != this.balls[i]) {
          x2 = this.balls[i].x;

          y2 = this.balls[i].y;

          //判断位置的平方和小球的圆心坐标的关系

          if (Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2) <= Math.pow(40, 2)) {
            //判断传过来的小球对象，相对于碰撞小球的哪个方位

            if (ballObj.x < this.balls[i].x) {
              if (ballObj.y < this.balls[i].y) {
                //小球对象在被碰小球的左上角

                ballObj.yflag = false;

                ballObj.xflag = false;
              } else if (ballObj.y > this.balls[i].y) {
                //小球对象在被碰小球的左下角

                ballObj.xflag = false;

                ballObj.yflag = true;
              } else {
                //小球对象在被撞小球的正左方

                ballObj.xflag = false;
              }
            } else if (ballObj.x > this.balls[i].x) {
              if (ballObj.y < this.balls[i].y) {
                //小球对象在被碰撞小球的右上方

                ballObj.yflag = false;

                ballObj.xflag = true;
              } else if (ballObj.y > this.balls[i].y) {
                //小球对象在被碰撞小球的右下方

                ballObj.xflag = true;

                ballObj.yflag = true;
              } else {
                //小球对象在被撞小球的正右方

                ballObj.xflag = true;
              }
            } else if (ballObj.y > this.balls[i].y) {
              //小球对象在被撞小球的正下方

              ballObj.yflag = true;
            } else if (ballObj.y < this.balls[i].y) {
              //小球对象在被撞小球的正上方

              ballObj.yflag = false;
            }
          }
        }
      }
    },
    Crash(ballObj, ignoreStart, ignoreEnd) {
      let x1 = "";
      let y1 = "";
      let r1 = "";
      let r2 = "";
      let x2 = "";
      let y2 = "";
      let speed = 300;
      //通过传过来的小球对象来获取小球的X坐标和Y坐标

      x1 = ballObj.x;
      y1 = ballObj.y;
      r1 = ballObj.size;
      for (let i = 0; i < this.balls.length; i++) {
        //确保不和自己对比
        if ((ballObj != this.balls[i] && i < ignoreStart) || i > ignoreEnd) {
          x2 = this.balls[i].x;
          y2 = this.balls[i].y;
          r2 = this.balls[i].size;

          //判断位置的平方和小球的圆心坐标的关系
          if (
            Math.pow(x1 - x2, 2) + Math.pow(y1 - y2, 2) + 4000 <=
            Math.pow(ballObj.offsetWidth / 4 + this.balls[i].offsetWidth / 4, 2)
          ) {
            //判断传过来的小球对象，相对于碰撞小球的哪个方位

            if (ballObj.x < this.balls[i].x) {
              if (ballObj.y < this.balls[i].y) {
                //小球对象在被碰小球的左上角
                this.moveToPosition(
                  this.balls[i],
                  this.balls[i].x + this.realPosition(12, "x"),
                  this.balls[i].y + this.realPosition(12, "y"),
                  speed
                );
              } else if (ballObj.y > this.balls[i].y) {
                //小球对象在被碰小球的左下角
                this.moveToPosition(
                  this.balls[i],
                  this.balls[i].x + this.realPosition(12, "x"),
                  this.balls[i].y - this.realPosition(12, "y"),
                  speed
                );
              } else {
                //小球对象在被撞小球的正左方
                this.moveToPosition(
                  this.balls[i],
                  this.balls[i].x + this.realPosition(24, "x"),
                  this.balls[i].y,
                  speed
                );
              }
            } else if (ballObj.x > this.balls[i].x) {
              if (ballObj.y < this.balls[i].y) {
                //小球对象在被碰撞小球的右上方
                this.moveToPosition(
                  this.balls[i],
                  this.balls[i].x - this.realPosition(24, "x"),
                  this.balls[i].y + this.realPosition(24, "y"),
                  speed
                );
              } else if (ballObj.y > this.balls[i].y) {
                //小球对象在被碰撞小球的右下方
                this.moveToPosition(
                  this.balls[i],
                  this.balls[i].x - this.realPosition(24, "x"),
                  this.balls[i].y - this.realPosition(24, "y"),
                  speed
                );
              } else {
                //小球对象在被撞小球的正右方
                this.moveToPosition(
                  this.balls[i],
                  this.balls[i].x - this.realPosition(24, "x"),
                  this.balls[i].y,
                  speed
                );
              }
            } else if (ballObj.y > this.balls[i].y) {
              //小球对象在被撞小球的正下方
              this.moveToPosition(
                this.balls[i],
                this.balls[i].x,
                this.balls[i].y + this.realPosition(24, "y"),
                speed
              );
            } else if (ballObj.y < this.balls[i].y) {
              //小球对象在被撞小球的正上方
              this.moveToPosition(
                this.balls[i],
                this.balls[i].x,
                this.balls[i].y - this.realPosition(24, "y"),
                speed
              );
            }
          }
        }
      }
    },

    watchWeb3Event() {
      let that = this;
      ethereum.on("disconnect", (res) => {
        console.log("已断开网络", res);
      });
      ethereum.on("connect", function (res) {
        console.log("已连接到网络", res);
      });
      ethereum.on("accountsChanged", function (res) {
        if (res[0]) {
          if (localStorage.getItem("wallet_address") != res[0]) {
            localStorage.setItem("wallet_address", res[0]);
          }
          that.musicStart = 0;
          localStorage.setItem("u_id", "");
          localStorage.setItem("imgurl1", "");
          that.currentAccounttext = res[0];
          that.walletAddress = res[0].substr(0, 4) + "***" + res[0].substr(-4);
          that.inputAddress = res[0].substr(0, 8);
          that.loginWallet = true;
          that.page = 1;
          that.arr = [];
          that.opensea();
        } else {
          that.musicStart = 0;
          localStorage.setItem("u_id", "");
          localStorage.setItem("imgurl1", "");
          that.loginWallet = false;
          that.haveTicket = false;
        }
      });
      ethereum.on("chainChanged", function (res) {
        const network = parseInt(res, 16);
        console.log(`networkID`, res, network);
        that.$store
          .dispatch("web3/set_network", network)
          .then((res) => {
            if (
              network == process.env.VUE_APP_MAIN_NETWORK ||
              network == process.env.VUE_APP_MAIN_NETWORK2
            ) {
              // that.$store.dispatch("mask/closeMask", "mainNetwork");
              // //  this.connectMetaMask()
              // console.log("已切换到连接主网络");
              // window.location.reload();
            } else {
              // that.$store.dispatch("mask/openMask", "mainNetwork");
            }
          })
          .catch((err) => {});
      });
      ethereum.on("message", (msg) => console.log(msg, 11111));
    },
    //倒计时
    secondsFormat() {
      this.endTime--;
      let day = this.formate(Math.floor(this.endTime / (24 * 3600))); // Math.floor()向下取整
      let hour = this.formate(
        Math.floor((this.endTime - day * 24 * 3600) / 3600)
      );
      let minute = this.formate(
        Math.floor((this.endTime - day * 24 * 3600 - hour * 3600) / 60)
      );
      let second = this.formate(
        this.endTime - day * 24 * 3600 - hour * 3600 - minute * 60
      );

      if (this.endTime <= 0) {
        this.time = `00:00:00`;
        return;
      }
      this.time = `${hour}:${minute}:${second}`;
    },
    formate(time) {
      if (time >= 10) {
        return time;
      } else {
        return `0${time}`;
      }
    },
    //倒计时接口
    async timeOut() {
      let data = await timeOut();
      this.endTime = data.data;
      if (this.endTime <= 0) {
        // alert()
        this.musicStart = 0;
        this.$refs.active.classList.remove("opacityActive");
        this.$refs.active.classList.add("zIndex");
        localStorage.setItem("u_id", "");
        localStorage.setItem("imgurl1", "");
      } else {
        this.$refs.active.classList.add("opacityActive");
        // 音频
        this.fetch_rtc_token();
      }
      let timer = setInterval(() => {
        if (this.endTime <= 0) {
          clearInterval(timer);
        }
        this.secondsFormat();
      }, 1000);
    },
    goback() {
      this.$router.push("/");
    },
    changeLang() {
      this.decipherment = !this.decipherment;
    },
    language() {
      let language = null;
      if (navigator.appName == "Netscape") {
        language = navigator.language;
      } else {
        language = navigator.browserLanguage;
      }
      if (language.indexOf("en") > -1) {
        this.lang = "en";
        this.decipherment = true;
        // alert("yingwen");
      } else if (language.indexOf("zh") > -1) {
        this.lang = "zh";
        this.decipherment = false;
        // alert("zhongwen");
      } else {
        this.lang = "en";
        this.decipherment = true;
        // alert("yingwen");
      }
      console.log(this.lang);
    },
    nfcMove() {
      if (this.clear != "") {
        clearInterval(this.clear);
      }
      this.clear = setInterval(move1, 10);
      let div1 = document.getElementById("popup");
      //div（广告）获取节
      // div(广告)初始的位置
      let offsetx = 0;
      let offsety = 0;
      //div(广告)每次移动的距离
      let stepx = 1;
      let stepy = 1;
      //设置定位
      div1.style.position = "fixed";
      div1.style.top = "offsetx";
      div1.style.left = "offsety";

      //网页可视化宽高--div(广告)可以移动的区域
      let seeWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      let seeHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      //div(广告)最大可移动的宽度、高度
      let maxLeft = seeWidth - 230;
      let maxTop = seeHeight - 170;

      function move1() {
        offsetx += stepx;
        offsety += stepy;
        // console.log(seeWidth);
        // console.log(seeHeight)
        //大于可移动的高度或到达顶部 就让移动的距离变为它的负数
        if (offsety >= maxTop || offsety <= 0) {
          stepy = -stepy;
        }
        //大于可移动的宽度或到达最左 就让移动的距离变为它的负数
        if (offsetx >= maxLeft || offsetx <= 0) {
          stepx = -stepx;
        }
        //div定位的位置
        div1.style.top = offsety + "px";
        div1.style.left = offsetx + "px";
        // console.log(div1.style.top);
        // console.log(div1.style.left);
      }

      // clearInterval(t);
      //启动定时器

      // //鼠标移入清除定时器
      // div1.onmouseenter = function () {
      //   clearInterval(t);
      // };
      // //鼠标移出恢复
      // div1.onmouseleave = function () {
      //   t = setInterval(move1, 5);
      // };
    },
    nfcMove2() {
      if (this.clear1 != "") {
        clearInterval(this.clear1);
      }

      this.clear1 = setInterval(move1, 10);
      let div1 = document.getElementById("popup2");
      //div（广告）获取节
      // div(广告)初始的位置
      let offsetx = 500;
      let offsety = 500;
      //div(广告)每次移动的距离
      let stepx = 1;
      let stepy = 1;
      //设置定位
      div1.style.position = "fixed";
      div1.style.top = "offsetx";
      div1.style.left = "offsety";

      //网页可视化宽高--div(广告)可以移动的区域
      let seeWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      let seeHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      //div(广告)最大可移动的宽度、高度
      let maxLeft = seeWidth - 230;
      let maxTop = seeHeight - 170;

      function move1() {
        offsetx += stepx;
        offsety += stepy;
        // console.log(seeWidth);
        // console.log(seeHeight)
        //大于可移动的高度或到达顶部 就让移动的距离变为它的负数
        if (offsety >= maxTop || offsety <= 0) {
          stepy = -stepy;
        }
        //大于可移动的宽度或到达最左 就让移动的距离变为它的负数
        if (offsetx >= maxLeft || offsetx <= 0) {
          stepx = -stepx;
        }
        //div定位的位置
        div1.style.top = offsety + "px";
        div1.style.left = offsetx + "px";
        // console.log(div1.style.top);
        // console.log(div1.style.left);
      }

      //启动定时器

      // //鼠标移入清除定时器
      // div1.onmouseenter = function () {
      //   clearInterval(t);
      // };
      // //鼠标移出恢复
      // div1.onmouseleave = function () {
      //   t = setInterval(move1, 5);
      // };
    },
    nfcMove3() {
      if (this.clear2 != "") {
        clearInterval(this.clear2);
      }
      this.clear2 = setInterval(move1, 10);
      let div1 = document.getElementById("popup3");
      //div（广告）获取节
      // div(广告)初始的位置
      let offsetx = 1200;
      let offsety = 100;
      //div(广告)每次移动的距离
      let stepx = 1;
      let stepy = 1;
      //设置定位
      div1.style.position = "fixed";
      div1.style.top = "offsetx";
      div1.style.left = "offsety";

      //网页可视化宽高--div(广告)可以移动的区域
      let seeWidth =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;
      let seeHeight =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight;
      //div(广告)最大可移动的宽度、高度
      let maxLeft = seeWidth - 230;
      let maxTop = seeHeight - 170;

      function move1() {
        offsetx += stepx;
        offsety += stepy;
        // console.log(seeWidth);
        // console.log(seeHeight)
        //大于可移动的高度或到达顶部 就让移动的距离变为它的负数
        if (offsety >= maxTop || offsety <= 0) {
          stepy = -stepy;
        }
        //大于可移动的宽度或到达最左 就让移动的距离变为它的负数
        if (offsetx >= maxLeft || offsetx <= 0) {
          stepx = -stepx;
        }
        //div定位的位置
        div1.style.top = offsety + "px";
        div1.style.left = offsetx + "px";
        // console.log(div1.style.top);
        // console.log(div1.style.left);
      }

      //启动定时器

      // //鼠标移入清除定时器
      // div1.onmouseenter = function () {
      //   clearInterval(t);
      // };
      // //鼠标移出恢复
      // div1.onmouseleave = function () {
      //   t = setInterval(move1, 5);
      // };
    },
  },
  computed: {},
};
</script>

<style src="./index.scss" lang="scss" scoped></style>


<style src="./index.mobile.scss" lang="scss" scoped></style>
