import request from '@/utils/request'
import baseURL from '@/utils/baseURL'

//加入派对
export function listNft(params) {
  return request({
    url: baseURL['collection'] + '/v2/item/list',
    method: 'get',
    params
  })
}



